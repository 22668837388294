.tab-container {
  box-sizing: border-box;
  padding: 5px 2em;
  width: 100%;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 5px 0;
  transition: ease-in-out 100ms;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      border: 0;
      border-radius: 50%;
      color: #4b4b4b;
      background-color: transparent;
      font-size: 25px;
      width: 25px;
      height: 25px;
      transition: ease-in-out 200ms;
      cursor: pointer;

      &:hover {
        color: rgb(255, 58, 58);
      }

      &:focus {
        outline: none;
      }
    }
  }
}