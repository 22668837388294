.Register {
  .bgWrapper {
    background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
    background-image: url("../../../assets/background/bg.jpg");
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }

  .loading {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .loading-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: fit-content;
      height: fit-content;
      padding: 5px;
      background-color: rgba(255, 255, 255, 0.911);
      border-radius: 5px;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      .loader {
        border: 8px solid #f3f3f3; /* Light grey */
        border-top: 8px solid rgba(0, 137, 181, 1); /* Blue */
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
  }

  .dialog {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .dialog-content {
      position: absolute;
      display: flex;
      flex-flow: row wrap;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 95vw;
      width: 600px;
      height: 200px;
      background-color: white;
      border-radius: 5px;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      align-items: center;
      justify-content: center;
      align-content: center;

      h4 {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #4B4B4B;
        font-weight: lighter;
      }

      a {
        color: #454545;
        font-weight: bold;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (min-width: 1366px) {
    .container {
      position: absolute;
      background-color: white;
      width: 35%;
      min-width: 528px;
      height: calc(var(--vh, 1vh) * 100);
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
    }

    .wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 70%;

      * {
        position: relative;
        margin-top: 18px;
        width: 100%;
        border-radius: 5px;
      }

      p {
        opacity: 0;
        color: rgba(255, 0, 0, 0.534);
        text-align: right;
        width: 98%;
        font-size: 14px;
        margin-top: 4px;

        &.show {
          opacity: 1;
        }
      }

      .title {
        text-align: center;
        font-size: 48px;
        margin-bottom: 0;
      }

      .sub-title {
        text-align: center;
        margin-bottom: 15px;
        margin-top: 0;
      }

      .tax-id {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .company {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &:disabled {
          background-color: #CBCBCB;
          color: #6B6B6B;
        }

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .email {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .confirm-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .register-btn {
        margin-top: 35px;
        height: 44px;
        border: none;
        outline: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }

        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 24px;
      }

      .login {
        opacity: 1;
        font-size: 16px;
        margin-top: 0;
        text-align: center;
        color: #454545;

        a {
          color: #454545;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1366px) and (min-height: 480px) {
    .container {
      position: absolute;
      background-color: white;
      width: 50%;
      height: calc(var(--vh, 1vh) * 100);
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
    }

    .wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      * {
        position: relative;
        margin-top: 18px;
        width: 100%;
        border-radius: 5px;
      }

      p {
        opacity: 0;
        color: rgba(255, 0, 0, 0.534);
        text-align: right;
        width: 98%;
        font-size: 14px;
        margin-top: 4px;

        &.show {
          opacity: 1;
        }
      }

      .title {
        text-align: center;
        font-size: 48px;
        margin-bottom: 0;
      }

      .sub-title {
        text-align: center;
        margin-bottom: 15px;
        margin-top: 0;
      }

      .tax-id {
        box-sizing: border-box;
        margin-top: 0;

        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .company {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #CBCBCB;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .email {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .confirm-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .register-btn {
        margin-top: 35px;
        height: 44px;
        border: none;
        outline: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }

        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 24px;
      }

      .login {
        opacity: 1;
        font-size: 16px;
        margin-top: 0;
        text-align: center;
        color: #454545;

        a {
          color: #454545;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1366px) and (max-height: 479px) {
    .bgWrapper {
      height: 170vh;
    }

    .container {
      position: absolute;
      background-color: white;
      width: 50%;
      height: 170vh;
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
    }

    .wrapper {
      position: absolute;
      left: 50%;
      top: 47.5%;
      transform: translate(-50%, -50%);
      width: 80%;

      * {
        position: relative;
        margin-top: 18px;
        width: 100%;
        border-radius: 5px;
      }

      p {
        opacity: 0;
        color: rgba(255, 0, 0, 0.534);
        text-align: right;
        width: 98%;
        font-size: 14px;
        margin-top: 4px;

        &.show {
          opacity: 1;
        }
      }

      .title {
        text-align: center;
        font-size: 48px;
        margin-bottom: 0;
      }

      .sub-title {
        text-align: center;
        margin-bottom: 15px;
        margin-top: 0;
      }

      .tax-id {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .company {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #CBCBCB;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .email {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .confirm-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .register-btn {
        margin-top: 35px;
        height: 44px;
        border: none;
        outline: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }

        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 24px;
      }

      .login {
        opacity: 1;
        font-size: 16px;
        margin-top: 0;
        text-align: center;
        color: #454545;

        a {
          color: #454545;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) and (min-height: 640px) {
    .container {
      position: absolute;
      background-color: white;
      width: 100%;
      height: 100%;
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
    }

    .wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      * {
        position: relative;
        margin-top: 18px;
        width: 100%;
        border-radius: 5px;
      }

      p {
        opacity: 0;
        color: rgba(255, 0, 0, 0.534);
        text-align: right;
        width: 98%;
        font-size: 13px;
        margin-top: 4px;

        &.show {
          opacity: 1;
        }
      }

      .title {
        margin-top: 10px;
        text-align: center;
        font-size: 48px;
        margin-bottom: 0;
        margin-top: 0;
      }

      .sub-title {
        text-align: center;
        margin-bottom: 15px;
        margin-top: 0;
      }

      .tax-id {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .company {
        box-sizing: border-box;
        margin-top: 0;

        height: 51px;
        border: none;
        outline: none;
        background-color: #CBCBCB;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .email {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .confirm-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .register-btn {
        margin-top: 35px;
        height: 44px;
        border: none;
        outline: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }

        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 24px;
      }

      .login {
        opacity: 1;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 10px;
        text-align: center;
        color: #454545;

        a {
          color: #454545;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) and (max-height: 639px) {
    .container {
      position: absolute;
      background-color: white;
      width: 100%;
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
    }

    .wrapper {
      position: relative;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      width: 80%;

      * {
        position: relative;
        margin-top: 18px;
        width: 100%;
        border-radius: 5px;
      }

      p {
        opacity: 0;
        color: rgba(255, 0, 0, 0.534);
        text-align: right;
        width: 98%;
        font-size: 13px;
        margin-top: 4px;

        &.show {
          opacity: 1;
        }
      }

      .title {
        margin-top: 0;
        text-align: center;
        font-size: 48px;
        margin-bottom: 0;
      }

      .sub-title {
        text-align: center;
        margin-bottom: 15px;
        margin-top: 0;
      }

      .tax-id {
        box-sizing: border-box;
        margin-top: 0;

        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .company {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #CBCBCB;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .email {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .confirm-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .register-btn {
        margin-top: 35px;
        height: 44px;
        border: none;
        outline: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }

        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 24px;
      }

      .login {
        opacity: 1;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 10px;
        text-align: center;
        color: #454545;

        a {
          color: #454545;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 359px) and (max-height: 639px) {
    .container {
      position: absolute;
      background-color: white;
      width: 100%;
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
    }

    .wrapper {
      position: relative;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      width: 80%;

      * {
        position: relative;
        margin-top: 18px;
        width: 100%;
        border-radius: 5px;
      }

      p {
        opacity: 0;
        color: rgba(255, 0, 0, 0.534);
        text-align: right;
        width: 98%;
        font-size: 10.5px;
        margin-top: 4px;

        &.show {
          opacity: 1;
        }
      }

      .title {
        margin-top: 0;
        text-align: center;
        font-size: 48px;
        margin-bottom: 0;
      }

      .sub-title {
        text-align: center;
        margin-bottom: 15px;
        margin-top: 0;
      }

      .tax-id {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .company {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #CBCBCB;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .email {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .confirm-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgba(255, 0, 0, 0.219);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgba(98, 180, 82, 0.219);
        }
      }

      .register-btn {
        margin-top: 35px;
        height: 44px;
        border: none;
        outline: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }

        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 24px;
      }

      .login {
        opacity: 1;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 10px;
        text-align: center;
        color: #454545;

        a {
          color: #454545;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}