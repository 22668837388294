.Admin-Home {
  @media only screen and (min-width: 1366px) {
    padding-top: 90px;
    padding-left: 10px;
    padding-right: 10px;

    .summary-wrapper {
      border-radius: 5px;
      background-color: #ececec;
      width: 100%;
      height: fit-content;
      margin-bottom: 10px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 10px;

      .company {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        width: 16%;
        height: 15vh;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
        }

        h2 {
          width: 100%;
        }
      }

      .account {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        width: 16%;
        height: 15vh;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
        }

        h2 {
          width: 100%;
        }
      }

      .file {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        width: 16%;
        height: 15vh;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
        }

        h2 {
          width: 100%;
        }
      }

      .waiting {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        width: 16%;
        height: 15vh;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
        }

        h2 {
          display: inline-flex;
          align-items: center;
        }
      }

      .online {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        width: 16%;
        height: 15vh;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
        }

        h2 {
          display: inline-flex;
          align-items: center;
        }
      }

      .offline {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 10px;
        box-sizing: border-box;
        width: 16%;
        height: 15vh;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
        }

        h2 {
          display: inline-flex;
          align-items: center;
        }
      }

      .update {
        display: flex;
        align-items: center;
        margin-left: auto;
        font-size: 16px;
        font-weight: lighter;
        margin-top: 5px;

        b{
          cursor: pointer;
        }
        .sync{
          cursor: pointer;
        }
        .disable{
          cursor: default;
          color: rgb(226, 226, 226);
        }
      }
    }

    .content {
      border-radius: 5px;
      background-color: #ececec;
      box-sizing: border-box;
      padding: 5px;
      margin-bottom: 10px;

      .inner-bar {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        width: 100%;
        height: 40px;
        margin-bottom: 5px;

        .btn-group {
          align-self: center;

          p {
            display: inline-flex;
          }

          button {
            font-family: inherit;
            margin: 0 5px;
            box-sizing: border-box;
            min-width: 70px;
            height: 30px;
            font-size: 16px;
            font-weight: bolder;
            border-radius: 5px;
            border: 0;
            color: #00536D;
            transition: all 150ms ease-in-out;
            background-color: transparent;
            cursor: pointer;

            &.selected {
              background-color: rgba(255, 255, 255, 0.8);
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
            }

            &:hover {
              background-color: rgba(255, 255, 255, 0.8);
            }

            &:focus {
              outline: none;
            }
          }
        }
      }

      .card-wrapper {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        max-height: calc(100% - 40px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  //Below PC HD to iPad(L)
  @media only screen and (max-width: 1365px) and (min-width: 768px) {
    padding-top: 90px;
    padding-left: 10px;
    padding-right: 10px;

    .summary-wrapper {
      border-radius: 5px;
      width: 100%;
      height: fit-content;
      margin-bottom: 10px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      box-sizing: border-box;

      .company {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 32%;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
        }

        h2 {
          width: 100%;
        }
      }

      .account {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 32%;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
        }

        h2 {
          width: 100%;
        }
      }

      .file {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 32%;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
        }

        h2 {
          width: 100%;
        }
      }

      .waiting {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 32%;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
        margin-top: 10px;

        h4 {
          width: 100%;
        }

        h2 {
          display: inline-flex;
          align-items: center;
        }
      }

      .online {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 32%;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
        margin-top: 10px;

        h4 {
          width: 100%;
        }

        h2 {
          display: inline-flex;
          align-items: center;
        }
      }

      .offline {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 32%;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
        margin-top: 10px;

        h4 {
          width: 100%;
        }

        h2 {
          display: inline-flex;
          align-items: center;
        }
      }

      .update {
        display: flex;
        align-items: center;
        margin-left: auto;
        font-size: 16px;
        font-weight: lighter;
        margin-top: 5px;

        b{
          cursor: pointer;
        }
        .sync{
          cursor: pointer;
        }
        .disable{
          cursor: default;
          color: rgb(226, 226, 226);
        }
      }
    }

    .content {
      border-radius: 5px;
      background-color: #ececec;
      box-sizing: border-box;
      padding: 5px;
      height: 70vh;

      .inner-bar {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        width: 100%;
        height: 40px;
        margin-bottom: 5px;

        .btn-group {
          align-self: center;

          p {
            display: inline-flex;
          }

          button {
            font-family: inherit;
            margin: 0 5px;
            box-sizing: border-box;
            min-width: 70px;
            height: 30px;
            font-size: 16px;
            font-weight: bolder;
            border-radius: 5px;
            border: 0;
            color: #00536D;
            transition: all 100ms ease-in-out;
            background-color: transparent;
            cursor: pointer;

            &.selected {
              background-color: rgba(255, 255, 255, 0.8);
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
            }

            &:hover {
              background-color: rgba(255, 255, 255, 0.8);
            }

            &:focus {
              outline: none;
            }
          }
        }
      }

      .card-wrapper {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        max-height: calc(100% - 40px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  //All Phone(P, L)
  @media only screen and (max-width: 767px) and (max-height: 1023px) and (min-height: 411px) {
    padding-top: 70px;
    padding-left: 10px;
    padding-right: 10px;

    .summary-wrapper {
      border-radius: 5px;
      width: 100%;
      height: fit-content;
      margin-bottom: 10px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      box-sizing: border-box;

      .company {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 31%;
        height: fit-content;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
        margin-bottom: 10px;

        h4 {
          width: 100%;
          font-size: 16px;
        }

        h2 {
          width: 100%;
          font-size: 18px;
        }
      }

      .account {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 31%;
        height: fit-content;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
        margin-bottom: 10px;

        h4 {
          width: 100%;
          font-size: 16px;
        }

        h2 {
          width: 100%;
          font-size: 18px;
        }
      }

      .file {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 31%;
        height: fit-content;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
        margin-bottom: 10px;

        h4 {
          width: 100%;
          font-size: 16px;
        }

        h2 {
          width: 100%;
          font-size: 18px;
        }
      }

      .waiting {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 31%;
        height: fit-content;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
          font-size: 16px;
        }

        h2 {
          display: inline-flex;
          align-items: center;
          font-size: 18px;
        }
      }

      .online {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 31%;
        height: fit-content;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
          font-size: 16px;
        }

        h2 {
          display: inline-flex;
          align-items: center;
          font-size: 18px;
        }
      }

      .offline {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
        box-sizing: border-box;
        width: 31%;
        height: fit-content;
        text-align: center;
        align-self: center;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);

        h4 {
          width: 100%;
          font-size: 14px;
          @media screen and (max-width:400px){
            font-size: 12px;
          }
        }

        h2 {
          display: inline-flex;
          align-items: center;
          font-size: 18px;
        }
      }

      .update {
        display: flex;
        align-items: center;
        margin-left: auto;
        font-size: 12px;
        font-weight: lighter;
        margin-top: 5px;

        b{
          cursor: pointer;
        }
        .sync{
          cursor: pointer;
        }
        .disable{
          cursor: default;
          color: rgb(226, 226, 226);
        }
      }
    }

    .content {
      border-radius: 5px;
      box-sizing: border-box;
      height: 65vh;
      @media screen and (max-width: 320px){
        height: 55vh;
      }

      .inner-bar {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background-color: #ececec;
        margin-bottom: 5px;

        .btn-group {
          display: flex;
          align-self: center;

          p {
            display: none;
          }

          button {
            font-family: inherit;
            margin: 0 5px;
            box-sizing: border-box;
            width: calc(85vw / 4);
            @media screen and (max-width:400px){
              width: calc(77vw / 4);
            }
            height: 30px;
            font-size: 16px;
            font-weight: bolder;
            border-radius: 5px;
            border: 0;
            color: #00536D;
            transition: all 100ms ease-in-out;
            background-color: transparent;
            cursor: pointer;

            &.selected {
              background-color: rgba(255, 255, 255, 0.8);
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
            }

            &:hover {
              background-color: rgba(255, 255, 255, 0.8);
            }

            &:focus {
              outline: none;
            }
          }
        }
      }

      .card-wrapper {
        width: 100%;
        max-height: calc(100% - 40px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
}