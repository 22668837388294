.Home {

  .container{
    .left-bar{
      .name{
        max-width: 100%;

        h3{
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2.5px;
    bottom: 2px;
    background-color: white;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked+.slider {
    background-color: #5ED66A;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #5ED66A;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .map{
    display: none;
    position: fixed;
    z-index: 1001;
    @media screen and (max-width:767){
      left: 100%
    }
    @media screen and (min-width:768){
      left: 0%
    }
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .map-content{
      @keyframes animatedialog {
        from {
          top: 450px;
          opacity: 0
        }

        to {
          top: 50%;
          opacity: 1
        }
      }

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 95vw;
      max-height: 70vh;
      min-height: 30vh;
      width: 640px;
      height: 50vh;
      background-color: white;
      border-radius: 5px;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      text-align-last: center;
      text-align: center;
      animation-name: animatedialog;
      animation-duration: 0.3s;

      .map-api{
        float: right;
        padding: 0.5% 5.5% 8%;
        // transform: translate(-50%,-50%);
        width: 90%;
        height: 80%;
      }
      .map-api2{
        position: absolute;
        left: 50%;
        top: 50%;
        width: fit-content;
        // float: right;
        // padding: 26% 4.5%;
        font-size: 24px;
        transform: translate(-50%,-50%);
        // width: 90%;
        // height: 80%;
      }

      .close {
        color: #aaaaaa;
        float: right;
        font-size: 30px;
        margin-bottom: 10px;
        height: 30px;
        width: 30px;
        font-weight: bold;
        margin-right: 10px;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &:hover {
          color: #838383;
        }

        &:focus {
          outline: none;
        }
      }

    }
  }

  .dialog {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .dialog-content {
      @keyframes animatedialog {
        from {
          top: 450px;
          opacity: 0
        }

        to {
          top: 50%;
          opacity: 1
        }
      }

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 95vw;
      width: 600px;
      height: 180px;
      background-color: white;
      border-radius: 5px;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      text-align-last: center;
      text-align: center;
      animation-name: animatedialog;
      animation-duration: 0.3s;

      h4 {
        position: relative;
        float: right;
        width: calc(100% - 80px);
        margin-bottom: 30px;
        margin-top: 35px;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #4B4B4B;
      }

      select {
        width: 60%;
        align-items: center;
        box-sizing: border-box;
        border-radius: 5px;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;
        justify-content: center;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          background-color: #e2e2e2;
        }
      }

      .add-db {
        height: 51px;
        margin-left: 10px;
        box-sizing: border-box;
        padding: 5px 20px;
        border-radius: 5px;
        border: none;
        outline: none;
        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 18px;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }
      }

      .close {
        color: #aaaaaa;
        float: right;
        font-size: 30px;
        margin-bottom: 10px;
        height: 30px;
        width: 30px;
        font-weight: bold;
        margin-right: 10px;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &:hover {
          color: #838383;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .del-dialog {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .dialog-content {
      @keyframes animatedialog {
        from {
          top: 450px;
          opacity: 0
        }

        to {
          top: 50%;
          opacity: 1
        }
      }

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 95vw;
      width: 600px;
      height: 200px;
      background-color: white;
      border-radius: 5px;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      text-align-last: center;
      text-align: center;
      animation-name: animatedialog;
      animation-duration: 0.3s;

      h4 {
        display: flex;
        width: 100%;
        height: 50%;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #4B4B4B;
      }

      .btn {
        height: 51px;
        margin-left: 10px;
        box-sizing: border-box;
        width: 100px;
        padding: 5px 20px;
        border-radius: 5px;
        border: none;
        outline: none;
        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 18px;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1);
        }

        &.cancel {
          background: transparent;
          color: rgb(0, 152, 202);

          &:hover {
            background: rgb(206, 252, 255);
            box-shadow: none;
          }
        }
      }
    }
  }

  //PC HD+
  @media only screen and (min-width: 1366px) {

    .container {
      position: fixed;
      background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
      background-image: url("../../../../assets/background/bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;

      .left-bar {
        position: fixed;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(3px) saturate(130%);
        min-width: 315px;
        max-width: 315px;
        height: calc(var(--vh, 1vh) * 100);
        z-index: 998;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);

        .name {
          padding: 0 2em;
          margin-top: 90px;
          box-sizing: border-box;
          
          h3 {
            font-size: 18px;
          }
        }

        .data-tab {
          overflow-y: hidden;
          overflow-x: hidden;
          height: calc(var(--vh, 1vh) * 100 - 250px);
          max-height: calc(var(--vh, 1vh) * 100 - 250px);
          max-width: 100%;

          .tabs{
            height: calc(100% - 260px);
            overflow-y: auto;
            overflow-x: hidden;
          }

          .current-tab {
            box-sizing: border-box;
            display: inline-flex;
            flex-flow: column wrap;
            justify-content: space-between;
            padding: 1em 2em;
            border-radius: 0 40px 0px 0;
            width: 100%;
            height: 260px;
            background-color: white;

            .head {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .title {
                max-width: 90%;
              }

              button {
                border: 0;
                border-radius: 50%;
                color: #4b4b4b;
                background-color: transparent;
                font-size: 25px;
                width: 25px;
                height: 25px;
                transition: ease-in-out 200ms;
                cursor: pointer;

                &:hover {
                  color: rgb(255, 58, 58);
                }

                &:focus {
                  outline: none;
                }
              }
            }

            .info {
              display: flex;
              flex-flow: row wrap;
              width: 100%;
              height: 32.5%;

              .user {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                align-items: center;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #32A8BF;
                }
              }

              .active-user {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #45C34A;
                }
              }

              .files {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #EBB916;
                }
              }

              .new-files {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #FF434F;
                }
              }
            }

            .report {

              .send-btn {
                margin: 0 10px;
                box-sizing: border-box;
                font-size: 16px;
                padding: 3px 10px;
                border: 0;
                border-radius: 5px;
                color: white;
                transition: all 200ms ease-in-out;
                background: linear-gradient(90deg, rgb(235, 207, 51) 0%, rgb(235, 141, 0) 100%);
                cursor: pointer;

                &:hover {
                  box-shadow: 0 0 8px 1px rgb(255, 187, 0)
                }

                &:focus {
                  outline: none;
                }
              }

              .download-btn {
                margin: 0 10px;
                box-sizing: border-box;
                font-size: 16px;
                padding: 3px 10px;
                border: 0;
                border-radius: 5px;
                color: white;
                transition: all 200ms ease-in-out;
                background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                cursor: pointer;

                &:hover {
                  box-shadow: 0 0 8px 1px rgb(69, 180, 195)
                }

                &:focus {
                  outline: none;
                }
              }
            }
          }
        }

        .new-btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          border: 0;
          height: 60px;
          font-size: 25px;
          color: #444444;
          background-color: rgba(255, 255, 255, 0.4);
          transition: ease-in-out 100ms;
          cursor: pointer;

          &:hover {
            background-color: rgb(255, 255, 255);
          }

          &:focus {
            outline: none;
          }
        }
      }

      .wrapper {
        box-sizing: border-box;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        padding-top: 95px;
        // padding-left: 375px;
        padding-left: 340px;
        padding-right: 20px;
        padding-bottom: 15px;
        align-self: start;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;

        &.fullscreen {
          padding-top: 20px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
        }

        .inner-bar {
          display: flex;
          justify-content: space-between;

          input {
            font-family: 'kanit', 'FontAwesome';
            box-sizing: border-box;
            min-width: 20vw;
            height: 35px;
            padding: 5px 15px;
            border: 0;
            border-radius: 30px;
            font-size: 16px;
            color: #005c91;
            transition: all 200ms ease-in-out;

            &:focus {
              outline: none;
              box-shadow: 0 0 10px 0 #00000048;
              ;
            }
          }

          //For Phone
          .mobile-sort {
            display: none;
          }

          .btn-group {
            align-self: center;
            min-width: 500px;

            button {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-family: inherit;
              margin: 0 5px;
              box-sizing: border-box;
              min-width: 150px;
              height: 30px;
              font-size: 16px;
              font-weight: bolder;
              border-radius: 5px;
              border: 0;
              color: #00536D;
              background-color: rgba(255, 255, 255, 0.5);
              transition: all 100ms ease-in-out;
              cursor: pointer;

              &.selected {
                background-color: rgba(255, 255, 255, 0.8);
              }

              &:hover {
                background-color: rgba(255, 255, 255, 0.8);
              }

              &:focus {
                outline: none;
              }
            }
          }
        }

        .card-wrapper {
          margin-top: 10px;
          margin-bottom: auto;
          width: 100%;
          max-height: calc(var(--vh, 1vh) * 100 - 195px);
          box-sizing: border-box;
          display: flex;
          flex-flow: row wrap;
          overflow-y: auto;
        }

        .inner-bar2 {
          display: inline-flex;
          width: 100%;
          justify-content: space-between;
          align-content: center;

          .btn-group {
            display: inline-flex;
            align-items: center;

            .present {
              display: flex;
              flex-flow: column wrap;

              .present-menu {
                display: none;
                position: absolute;
                box-sizing: border-box;
                padding: 10px;
                margin-left: 0;
                bottom: 50px;
                border-radius: 5px;
                background-color: #ffffff;
                max-width: 200px;
                height: fit-content;
                box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;

                .close-pmenu {
                  height: 10px;
                  color: #aaaaaa;
                  float: right;
                  font-size: 26px;
                  font-weight: bold;
                  margin-top: -15px;
                  border: 0;
                  background-color: transparent;
                  cursor: pointer;

                  &:hover {
                    color: #838383;
                  }

                  &:focus {
                    outline: none;
                  }
                }

                .btn-group {
                  margin: 5px 0;
                }

                input {
                  margin: 0 5px;
                  border: 0;
                  text-align: center;
                  font-size: 16px;
                  border-radius: 5px;
                  border: solid 1px rgb(211, 211, 211);

                  &:focus {
                    outline: none;
                  }
                }
              }

              .present-btn {
                position: relative;
                font-size: 16px;
                text-align: center;
                box-sizing: border-box;
                width: 40px;
                top: 15%;
                height: 30px;
                border-radius: 5px;
                border: 0;
                color: #00536D;
                background-color: rgba(255, 255, 255, 0.5);
                transition: all 100ms ease-in-out;
                cursor: pointer;

                &:focus {
                  outline: none;
                }
              }

              &:hover {
                .present-menu {
                  display: block;
                  border-radius: 5px;
                  border-radius: 5px;
                }

                .present-btn {
                  background-color: #ffffffcc;
                  border-radius: 5px;
                }
              }
            }

            .dropup {
              display: inline-flex;

              .dropbtn {
                font-family: 'kanit', 'FontAwesome';
                margin: 0 5px;
                box-sizing: border-box;
                min-width: 100px;
                height: 30px;
                font-size: 16px;
                font-weight: bolder;
                border-radius: 5px;
                border: 0;
                color: #00536D;
                background-color: rgba(255, 255, 255, 0.5);
                transition: all 100ms ease-in-out;
                cursor: pointer;

                &:focus {
                  outline: none;
                }
              }

              .dropup-content {
                display: none;
                position: absolute;
                margin-left: 5px;
                bottom: 45px;
                border-radius: 5px;
                background-color: #ffffff;
                min-width: 100px;
                box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;

                button {
                  border: none;
                  background-color: white;
                  width: 100%;
                  color: #434343;
                  padding: 5px 16px;
                  text-decoration: none;
                  text-align: center;
                  display: block;
                  border-radius: 5px;
                  font-size: 16px;
                  cursor: pointer;

                  &:hover {
                    background-color: #BCEAF9
                  }

                  &:focus {
                    outline: none;
                  }
                }
              }

              &:hover {
                .dropup-content {
                  display: block;
                  // border-radius: 5px 5px 0 0;
                }

                .dropbtn {
                  background-color: #ffffffcc;
                  // border-radius: 0 0 5px 5px;
                }
              }
            }
          }

          .pagination {
            box-sizing: border-box;
            align-self: center;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);

            button {
              font-family: 'kanit', 'FontAwesome';
              margin: 0 2px;
              box-sizing: border-box;
              min-width: 30px;
              height: 30px;
              font-size: 16px;
              font-weight: bolder;
              border-radius: 5px;
              border: 0;
              color: #00536D;
              background-color: transparent;
              transition: all 100ms ease-in-out;
              cursor: pointer;

              &.selected {
                color: #009ece;
                background-color: rgba(255, 255, 255, 1);
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
              }

              &:disabled {
                color: rgba(0, 83, 109, 0.5);
                cursor: default;

                &:hover {
                  background-color: transparent;
                }
              }

              &:hover {
                background-color: rgb(255, 255, 255);
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  //Below PC HD to iPad(L)
  @media only screen and (max-width: 1365px) and (min-width: 1024px){
    .container {
      background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
      background-image: url("../../../../assets/background/bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;

      .left-bar {
        position: fixed;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(3px) saturate(130%);
        min-width: 350px;
        max-width: 350px;
        height: calc(var(--vh, 1vh) * 100);
        z-index: 998;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);

        .name {
          padding: 0 2em;
          margin-top: 90px;
          box-sizing: border-box;
        }

        .data-tab {
          overflow-y: auto;
          overflow-x: hidden;
          height: calc(var(--vh, 1vh) * 100 - 250px);
          max-height: calc(var(--vh, 1vh) * 100 - 250px);
          max-width: 100%;

          .tabs{
            height: calc(100% - 260px);
            overflow-y: auto;
            overflow-x: hidden;
          }

          .current-tab {
            box-sizing: border-box;
            display: inline-flex;
            flex-flow: column wrap;
            justify-content: space-between;
            padding: 1em 2em;
            border-radius: 0 40px 0px 0;
            width: 100%;
            height: 260px;
            background-color: white;

            .head {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .title {
                max-width: 90%;
              }

              button {
                border: 0;
                border-radius: 50%;
                color: #4b4b4b;
                background-color: transparent;
                font-size: 25px;
                width: 25px;
                height: 25px;
                transition: ease-in-out 200ms;
                cursor: pointer;

                &:hover {
                  color: rgb(255, 58, 58);
                }

                &:focus {
                  outline: none;
                }
              }
            }

            .info {
              display: flex;
              flex-flow: row wrap;
              width: 100%;
              height: 32.5%;

              .user {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                align-items: center;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #32A8BF;
                }
              }

              .active-user {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #45C34A;
                }
              }

              .files {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #EBB916;
                }
              }

              .new-files {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #FF434F;
                }
              }
            }

            .report {

              .send-btn {
                margin: 0 10px;
                box-sizing: border-box;
                font-size: 16px;
                padding: 3px 10px;
                border: 0;
                border-radius: 5px;
                color: white;
                transition: all 200ms ease-in-out;
                background: linear-gradient(90deg, rgb(235, 207, 51) 0%, rgb(235, 141, 0) 100%);
                cursor: pointer;

                &:hover {
                  box-shadow: 0 0 8px 1px rgb(255, 187, 0)
                }

                &:focus {
                  outline: none;
                }
              }

              .download-btn {
                margin: 0 10px;
                box-sizing: border-box;
                font-size: 16px;
                padding: 3px 10px;
                border: 0;
                border-radius: 5px;
                color: white;
                transition: all 200ms ease-in-out;
                background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                cursor: pointer;

                &:hover {
                  box-shadow: 0 0 8px 1px rgb(69, 180, 195)
                }

                &:focus {
                  outline: none;
                }
              }
            }
          }
        }

        .new-btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          border: 0;
          height: 60px;
          font-size: 25px;
          color: #444444;
          background-color: rgba(255, 255, 255, 0.4);
          transition: ease-in-out 100ms;
          cursor: pointer;

          &:hover {
            background-color: rgb(255, 255, 255);
          }

          &:focus {
            outline: none;
          }
        }
      }

      .wrapper {
        box-sizing: border-box;
        width: 100vw;
        height: calc(var(--vh, 1vh) * 100);
        padding-top: 95px;
        padding-left: 375px;
        padding-right: 20px;
        padding-bottom: 15px;
        align-self: start;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;

        &.fullscreen {
          padding-top: 20px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
        }

        .inner-bar {
          width: 100%;
          display: flex;
          justify-content: space-between;

          input {
            font-family: 'kanit', 'FontAwesome';
            box-sizing: border-box;
            width: 20vw;
            min-width: 15vw;
            height: 35px;
            padding: 5px 15px;
            border: 0;
            border-radius: 30px;
            font-size: 16px;
            color: #005c91;
            transition: all 200ms ease-in-out;

            &:focus {
              outline: none;
              box-shadow: 0 0 10px 0 #00000048;
              ;
            }
          }

          .mobile-sort {
            display: none;
            align-self: flex-end;

            button {
              display: flex;
              border: none;
              background-color: transparent;

              &:focus {
                outline: none;
              }
            }
          }

          .btn-group {
            align-self: center;
            min-width: 300px;

            p {
              font-size: 14px;
            }

            button {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-family: inherit;
              margin: 0 5px;
              box-sizing: border-box;
              min-width: 100px;
              height: 30px;
              font-size: 14px;
              font-weight: bolder;
              border-radius: 5px;
              border: 0;
              color: #00536D;
              background-color: rgba(255, 255, 255, 0.5);
              transition: all 100ms ease-in-out;
              cursor: pointer;

              &.selected {
                background-color: rgba(255, 255, 255, 0.8);
              }

              &:hover {
                background-color: rgba(255, 255, 255, 0.8);
              }

              &:focus {
                outline: none;
              }
            }
            @media screen and (max-width:1000px){
              margin-left: 5px;
              p {
                font-size: 10px;
              }
              button{
                font-size: 10px;
                min-width: 60px;
              }
            }
          }
        }

        .card-wrapper {
          margin-top: 10px;
          margin-bottom: auto;
          width: 100%;
          max-height: calc(var(--vh, 1vh) * 100 - 195px);
          box-sizing: border-box;
          display: flex;
          flex-flow: row wrap;
          overflow-y: auto;
        }

        .inner-bar2 {
          display: inline-flex;
          width: 100%;
          justify-content: space-between;
          align-content: center;

          .btn-group {
            display: inline-flex;
            align-items: center;

            .present {
              display: flex;
              flex-flow: column wrap;

              .present-menu {
                display: none;
                position: absolute;
                box-sizing: border-box;
                padding: 10px;
                margin-left: 0;
                bottom: 50px;
                border-radius: 5px;
                background-color: #ffffff;
                max-width: 200px;
                height: fit-content;
                box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;

                .close-pmenu {
                  height: 10px;
                  color: #aaaaaa;
                  float: right;
                  font-size: 26px;
                  font-weight: bold;
                  margin-top: -15px;
                  border: 0;
                  background-color: transparent;
                  cursor: pointer;

                  &:hover {
                    color: #838383;
                  }

                  &:focus {
                    outline: none;
                  }
                }

                .btn-group {
                  margin: 5px 0;
                }

                input {
                  margin: 0 5px;
                  border: 0;
                  text-align: center;
                  font-size: 16px;
                  border-radius: 5px;
                  border: solid 1px rgb(211, 211, 211);

                  &:focus {
                    outline: none;
                  }
                }
              }

              .present-btn {
                position: relative;
                font-size: 16px;
                text-align: center;
                box-sizing: border-box;
                width: 40px;
                top: 15%;
                height: 30px;
                border-radius: 5px;
                border: 0;
                color: #00536D;
                background-color: rgba(255, 255, 255, 0.5);
                transition: all 100ms ease-in-out;
                cursor: pointer;

                &:focus {
                  outline: none;
                }
              }

              &:hover {
                .present-menu {
                  display: block;
                  border-radius: 5px;
                  border-radius: 5px;
                }

                .present-btn {
                  background-color: #ffffffcc;
                  border-radius: 5px;
                }
              }
            }

            .dropup {
              display: inline-flex;

              .dropbtn {
                font-family: 'kanit', 'FontAwesome';
                margin: 0 5px;
                box-sizing: border-box;
                min-width: 100px;
                height: 30px;
                font-size: 16px;
                font-weight: bolder;
                border-radius: 5px;
                border: 0;
                color: #00536D;
                background-color: rgba(255, 255, 255, 0.5);
                transition: all 100ms ease-in-out;
                cursor: pointer;

                &:focus {
                  outline: none;
                }
              }

              .dropup-content {
                display: none;
                position: absolute;
                margin-left: 5px;
                bottom: 45px;
                border-radius: 5px;
                background-color: #ffffff;
                min-width: 100px;
                box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;

                button {
                  border: none;
                  background-color: white;
                  width: 100%;
                  color: #434343;
                  padding: 5px 16px;
                  text-decoration: none;
                  text-align: center;
                  display: block;
                  border-radius: 5px;
                  font-size: 16px;
                  cursor: pointer;

                  &:hover {
                    background-color: #BCEAF9
                  }

                  &:focus {
                    outline: none;
                  }
                }
              }

              &:hover {
                .dropup-content {
                  display: block;
                  // border-radius: 5px 5px 0 0;
                }

                .dropbtn {
                  background-color: #ffffffcc;
                  // border-radius: 0 0 5px 5px;
                }
              }
            }
          }

          .pagination {
            box-sizing: border-box;
            align-self: center;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);

            button {
              font-family: 'kanit', 'FontAwesome';
              margin: 0 2px;
              box-sizing: border-box;
              min-width: 30px;
              height: 30px;
              font-size: 16px;
              font-weight: bolder;
              border-radius: 5px;
              border: 0;
              color: #00536D;
              background-color: transparent;
              transition: all 100ms ease-in-out;
              cursor: pointer;

              &.selected {
                color: #009ece;
                background-color: rgba(255, 255, 255, 1);
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
              }

              &:disabled {
                color: rgba(0, 83, 109, 0.5);
                cursor: default;

                &:hover {
                  background-color: transparent;
                }
              }

              &:hover {
                background-color: rgb(255, 255, 255);
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1023px) and (min-width: 768px){
    .container {
      background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
      background-image: url("../../../../assets/background/bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;

      .left-bar {
        position: fixed;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(3px) saturate(130%);
        min-width: 320px;
        max-width: 320px;
        width: 100vw;
        height: calc(100% - 80px);
        left: 0;
        bottom: 0;
        z-index: 998;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);

        .name {
          padding: 0 2em;
          margin-top: 10px;
          box-sizing: border-box;
        }

        .data-tab {
          overflow-y: auto;
          overflow-x: hidden;
          height: calc(var(--vh, 1vh) * 100 - 200px);
          width: 100%;

          .tabs{
            height: calc(100% - 260px);
            overflow-y: auto;
            overflow-x: hidden;
          }

          .current-tab {
            box-sizing: border-box;
            display: inline-flex;
            flex-flow: column wrap;
            justify-content: space-between;
            padding: 1em 2em;
            width: 100%;
            height: 260px;
            background-color: white;
            border-radius: 0 40px 0px 0;

            .head {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .title {
                max-width: 90%;
                font-size: 14px;
              }

              button {
                border: 0;
                border-radius: 50%;
                color: #4b4b4b;
                background-color: transparent;
                font-size: 25px;
                width: 25px;
                height: 25px;
                transition: ease-in-out 200ms;
                cursor: pointer;

                &:hover {
                  color: rgb(255, 58, 58);
                }

                &:focus {
                  outline: none;
                }
              }
            }

            .info {
              display: flex;
              flex-flow: row wrap;
              width: 100%;
              height: 32.5%;

              .user {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                align-items: center;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #32A8BF;
                }
              }

              .active-user {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #45C34A;
                }
              }

              .files {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #EBB916;
                }
              }

              .new-files {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #FF434F;
                }
              }
            }

            .report {

              .send-btn {
                margin: 0 10px;
                box-sizing: border-box;
                font-size: 14px;
                padding: 3px 10px;
                border: 0;
                border-radius: 5px;
                color: white;
                transition: all 200ms ease-in-out;
                background: linear-gradient(90deg, rgb(235, 207, 51) 0%, rgb(235, 141, 0) 100%);
                cursor: pointer;

                &:hover {
                  box-shadow: 0 0 8px 1px rgb(255, 187, 0)
                }

                &:focus {
                  outline: none;
                }
              }

              .download-btn {
                margin: 0 10px;
                box-sizing: border-box;
                font-size: 14px;
                padding: 3px 10px;
                border: 0;
                border-radius: 5px;
                color: white;
                transition: all 200ms ease-in-out;
                background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                cursor: pointer;

                &:hover {
                  box-shadow: 0 0 8px 1px rgb(69, 180, 195)
                }

                &:focus {
                  outline: none;
                }
              }
            }
          }
        }

        .new-btn {
          bottom: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          border: 0;
          height: 60px;
          font-size: 25px;
          color: #444444;
          background-color: rgba(255, 255, 255, 0.4);
          transition: ease-in-out 100ms;
          cursor: pointer;

          &:hover {
            background-color: rgb(255, 255, 255);
          }

          &:focus {
            outline: none;
          }
        }
      }

      .wrapper {
        box-sizing: border-box;
        width: calc(100% - 320px);
        height: calc(var(--vh, 1vh) * 100 - 60px);
        top: 80px;
        left: 320px;
        padding: 15px 15px 15px 15px;
        background-image: url("../../../../assets/background/bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        background-color: rgb(28, 145, 192);
        position: fixed;
        align-self: start;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;

        &.fullscreen {
          padding-top: 20px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
        }

        .inner-bar {
          display: flex;
          justify-content: space-between;

          input {
            font-family: 'kanit', 'FontAwesome';
            box-sizing: border-box;
            min-width: 87%;
            height: 35px;
            padding: 5px 15px;
            border: 0;
            border-radius: 30px;
            font-size: 16px;
            color: #005c91;
            transition: all 200ms ease-in-out;

            &:focus {
              outline: none;
              box-shadow: 0 0 10px 0 #00000048;
              ;
            }
          }

          .mobile-sort {
            display: inline-flex;
            align-self: flex-end;
            margin-right: 20px;

            button {
              display: flex;
              border: none;
              background-color: transparent;

              &:hover {
                border-radius: 5px;
                background-color: rgb(28, 132, 153);
              }

              &:focus {
                outline: none;
              }
            }

            .mobile-sort-menu {
              position: fixed;
              box-sizing: border-box;
              padding: 10px;
              top: 135px;
              right: 30px;
              border-radius: 0 0 5px 5px;
              background-color: #ffffff;
              min-width: 40vw;
              height: 120px;
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
              z-index: 999;

              .mobile-sort-btn {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;

                p {
                  width: 100%;
                }

                button {
                  margin: 5px;
                  width: 45%;
                  border-radius: 5px;
                  background-color: #e4e4e4;
                  align-items: center;
                  justify-content: center;
                  font-size: 16px;

                  &.selected {
                    color: white;
                    background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                  }
                }

                select {
                  margin-top: 14px;
                  width: 25%;
                  height: fit-content;
                  align-content: center;
                  border-radius: 5px;
                  text-align-last: center;
                  text-align: center;
                  border: none;
                  background-color: transparent;
                  font-size: 16px;
                  font-weight: bold;
                  color: #00536D;
                  background-color: #e4e4e4;

                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }

          .btn-group {
            display: none;
          }
        }

        .card-wrapper {
          margin-top: 10px;
          margin-bottom: auto;
          width: 100%;
          max-height: calc(var(--vh, 1vh) * 100 - 195px);
          box-sizing: border-box;
          display: flex;
          flex-flow: row wrap;
          overflow-y: auto;
        }

        .inner-bar2 {
          margin-bottom: 15px;
          display: inline-flex;
          width: 100%;
          justify-content: center;
          align-content: center;

          .btn-group {
            display: none;
            align-items: center;
          }

          .pagination {
            box-sizing: border-box;
            align-self: center;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);

            button {
              font-family: 'kanit', 'FontAwesome';
              margin: 0 2px;
              box-sizing: border-box;
              min-width: 30px;
              height: 30px;
              font-size: 16px;
              font-weight: bolder;
              border-radius: 5px;
              border: 0;
              color: #00536D;
              background-color: transparent;
              transition: all 100ms ease-in-out;
              cursor: pointer;

              &.selected {
                color: #009ece;
                background-color: rgba(255, 255, 255, 1);
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
              }

              &:disabled {
                color: rgba(0, 83, 109, 0.5);
                cursor: default;

                &:hover {
                  background-color: transparent;
                }
              }

              &:hover {
                background-color: rgb(255, 255, 255);
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  //All Phone(P, L)
  @media only screen and (max-width: 767px) and (min-width: 375px) and (orientation: portrait){
    .container {
      background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
      background-image: url("../../../../assets/background/bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;

      .left-bar {
        position: fixed;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(3px) saturate(130%);
        min-width: 320px;
        width: 100vw;
        height: calc(100% - 60px);
        left: 0;
        bottom: 0;
        z-index: 998;

        .name {
          padding: 0 2em;
          margin-top: 10px;
          box-sizing: border-box;
        }

        .data-tab {
          overflow-y: auto;
          overflow-x: hidden;
          height: calc(var(--vh, 1vh) * 100 - 200px);
          width: 100%;

          .tabs{
            height: calc(100% - 260px);
            overflow-y: auto;
            overflow-x: hidden;
          }

          .current-tab {
            box-sizing: border-box;
            display: inline-flex;
            flex-flow: column wrap;
            justify-content: space-between;
            padding: 1em 2em;
            width: 100%;
            height: 260px;
            background-color: white;

            .head {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .title {
                max-width: 90%;
                font-size: 14px;
              }

              button {
                border: 0;
                border-radius: 50%;
                color: #4b4b4b;
                background-color: transparent;
                font-size: 25px;
                width: 25px;
                height: 25px;
                transition: ease-in-out 200ms;
                cursor: pointer;

                &:hover {
                  color: rgb(255, 58, 58);
                }

                &:focus {
                  outline: none;
                }
              }
            }

            .info {
              display: flex;
              flex-flow: row wrap;
              width: 100%;
              height: 32.5%;

              .user {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                align-items: center;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #32A8BF;
                }
              }

              .active-user {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #45C34A;
                }
              }

              .files {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #EBB916;
                }
              }

              .new-files {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 20px;
                  margin-right: 5px;
                  color: #FF434F;
                }
              }
            }

            .report {

              .send-btn {
                margin: 0 10px;
                box-sizing: border-box;
                font-size: 14px;
                padding: 3px 10px;
                border: 0;
                border-radius: 5px;
                color: white;
                transition: all 200ms ease-in-out;
                background: linear-gradient(90deg, rgb(235, 207, 51) 0%, rgb(235, 141, 0) 100%);
                cursor: pointer;

                &:hover {
                  box-shadow: 0 0 8px 1px rgb(255, 187, 0)
                }

                &:focus {
                  outline: none;
                }
              }

              .download-btn {
                margin: 0 10px;
                box-sizing: border-box;
                font-size: 14px;
                padding: 3px 10px;
                border: 0;
                border-radius: 5px;
                color: white;
                transition: all 200ms ease-in-out;
                background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                cursor: pointer;

                &:hover {
                  box-shadow: 0 0 8px 1px rgb(69, 180, 195)
                }

                &:focus {
                  outline: none;
                }
              }
            }
          }
        }

        .new-btn {
          bottom: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          border: 0;
          height: 60px;
          font-size: 25px;
          color: #444444;
          background-color: rgba(255, 255, 255, 0.4);
          transition: ease-in-out 100ms;
          cursor: pointer;

          &:hover {
            background-color: rgb(255, 255, 255);
          }

          &:focus {
            outline: none;
          }
        }
      }

      .wrapper {
        box-sizing: border-box;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100 - 60px);
        top: 60px;
        left: 100%;
        padding: 15px 15px 15px 15px;
        background-image: url("../../../../assets/background/bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        background-color: rgb(28, 145, 192);
        position: fixed;
        align-self: start;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;

        &.fullscreen {
          padding-top: 20px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
        }

        .inner-bar {
          display: flex;
          justify-content: space-between;

          input {
            font-family: 'kanit', 'FontAwesome';
            box-sizing: border-box;
            min-width: 90%;
            height: 35px;
            padding: 5px 15px;
            border: 0;
            border-radius: 30px;
            font-size: 16px;
            color: #005c91;
            transition: all 200ms ease-in-out;

            &:focus {
              outline: none;
              box-shadow: 0 0 10px 0 #00000048;
              ;
            }
          }

          .mobile-sort {
            display: inline-flex;
            align-self: flex-end;

            button {
              display: flex;
              border: none;
              background-color: transparent;

              &:hover {
                border-radius: 5px;
                background-color: rgb(28, 132, 153);
              }

              &:focus {
                outline: none;
              }
            }

            .mobile-sort-menu {
              position: fixed;
              box-sizing: border-box;
              padding: 10px;
              top: 120px;
              left: 100vw;
              border-radius: 0 0 5px 5px;
              background-color: #ffffff;
              min-width: 100vw;
              height: 120px;
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
              z-index: 999;

              .mobile-sort-btn {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;

                p {
                  width: 100%;
                }

                button {
                  margin: 5px;
                  width: 45%;
                  border-radius: 5px;
                  background-color: #e4e4e4;
                  align-items: center;
                  justify-content: center;
                  font-size: 16px;

                  &.selected {
                    color: white;
                    background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                  }
                }

                select {
                  margin-top: 14px;
                  height: fit-content;
                  width: 25%;
                  border-radius: 5px;
                  text-align-last: center;
                  text-align: center;
                  border: none;
                  background-color: transparent;
                  font-size: 16px;
                  font-weight: bold;
                  color: #00536D;
                  background-color: #e4e4e4;

                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }

          .btn-group {
            display: none;
          }
        }

        .card-wrapper {
          margin-top: 10px;
          margin-bottom: auto;
          width: 100%;
          max-height: calc(var(--vh, 1vh) * 100 - 195px);
          box-sizing: border-box;
          display: flex;
          flex-flow: row wrap;
          overflow-y: auto;
        }

        .inner-bar2 {
          display: inline-flex;
          width: 100%;
          justify-content: center;
          align-content: center;

          .btn-group {
            display: none;
            align-items: center;
          }

          .pagination {
            box-sizing: border-box;
            align-self: center;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);

            button {
              font-family: 'kanit', 'FontAwesome';
              margin: 0 2px;
              box-sizing: border-box;
              min-width: 30px;
              height: 30px;
              font-size: 16px;
              font-weight: bolder;
              border-radius: 5px;
              border: 0;
              color: #00536D;
              background-color: transparent;
              transition: all 100ms ease-in-out;
              cursor: pointer;

              &.selected {
                color: #009ece;
                background-color: rgba(255, 255, 255, 1);
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
              }

              &:disabled {
                color: rgba(0, 83, 109, 0.5);
                cursor: default;

                &:hover {
                  background-color: transparent;
                }
              }

              &:hover {
                background-color: rgb(255, 255, 255);
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 374px) and (orientation: portrait) {
    .container {
      background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
      background-image: url("../../../../assets/background/bg.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;

      .left-bar {
        position: fixed;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(3px) saturate(130%);
        min-width: 310px;
        // max-width: 350px;
        width: 100%;
        height: calc(100% - 60px);
        left: 0;
        bottom: 0;
        z-index: 998;
        box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);

        .name {
          padding: 0 2em;
          margin-top: 4px;
          box-sizing: border-box;
        }

        .data-tab {
          overflow-y: auto;
          overflow-x: hidden;
          height: calc(var(--vh, 1vh) * 100 - 160px);
          width: 100%;

          .tabs{
            height: calc(100% - 190px);
            overflow-y: auto;
            overflow-x: hidden;
          }

          .current-tab {
            box-sizing: border-box;
            display: inline-flex;
            flex-flow: column wrap;
            justify-content: space-between;
            padding: 0.5em 2em;
            width: 100%;
            height: 190px;
            background-color: white;

            .head {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              .title {
                max-width: 90%;
                font-size: 12px;
              }

              button {
                border: 0;
                border-radius: 50%;
                color: #4b4b4b;
                background-color: transparent;
                font-size: 25px;
                width: 25px;
                height: 25px;
                transition: ease-in-out 200ms;
                cursor: pointer;

                &:hover {
                  color: rgb(255, 58, 58);
                }

                &:focus {
                  outline: none;
                }
              }
            }

            .info {
              display: flex;
              flex-flow: row wrap;
              width: 100%;
              height: 30%;

              .user {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                align-items: center;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 15px;
                  margin-right: 5px;
                  color: #32A8BF;
                }
              }

              .active-user {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 15px;
                  margin-right: 5px;
                  color: #45C34A;
                }
              }

              .files {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 15px;
                  margin-right: 5px;
                  color: #EBB916;
                }
              }

              .new-files {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                min-width: 50%;
                max-width: 50%;

                .icon {
                  font-size: 15px;
                  margin-right: 5px;
                  color: #FF434F;
                }
              }
            }

            .report {

              .send-btn {
                margin: 0 10px;
                box-sizing: border-box;
                font-size: 12px;
                padding: 3px 10px;
                border: 0;
                border-radius: 5px;
                color: white;
                transition: all 200ms ease-in-out;
                background: linear-gradient(90deg, rgb(235, 207, 51) 0%, rgb(235, 141, 0) 100%);
                cursor: pointer;

                &:hover {
                  box-shadow: 0 0 8px 1px rgb(255, 187, 0)
                }

                &:focus {
                  outline: none;
                }
              }

              .download-btn {
                margin: 0 10px;
                box-sizing: border-box;
                font-size: 12px;
                padding: 3px 10px;
                border: 0;
                border-radius: 5px;
                color: white;
                transition: all 200ms ease-in-out;
                background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                cursor: pointer;

                &:hover {
                  box-shadow: 0 0 8px 1px rgb(69, 180, 195)
                }

                &:focus {
                  outline: none;
                }
              }
            }
          }
        }

        .new-btn {
          bottom: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          border: 0;
          height: 40px;
          font-size: 25px;
          color: #444444;
          background-color: rgba(255, 255, 255, 0.4);
          transition: ease-in-out 100ms;
          cursor: pointer;

          &:hover {
            background-color: rgb(255, 255, 255);
          }

          &:focus {
            outline: none;
          }
        }
      }

      .wrapper {
        box-sizing: border-box;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100 - 60px);
        top: 60px;
        left: 100%;
        padding: 15px 15px 15px 15px;
        background-image: url("../../../../assets/background/bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        background-color: rgb(28, 145, 192);
        position: fixed;
        align-self: start;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;

        &.fullscreen {
          padding-top: 20px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
        }

        .inner-bar {
          display: flex;
          justify-content: space-between;

          input {
            font-family: 'kanit', 'FontAwesome';
            box-sizing: border-box;
            min-width: 90%;
            height: 35px;
            padding: 5px 15px;
            border: 0;
            border-radius: 30px;
            font-size: 16px;
            color: #005c91;
            transition: all 200ms ease-in-out;

            &:focus {
              outline: none;
              box-shadow: 0 0 10px 0 #00000048;
              ;
            }
          }

          .mobile-sort {
            display: inline-flex;
            align-self: flex-end;

            button {
              display: flex;
              border: none;
              background-color: transparent;

              &:hover {
                border-radius: 5px;
                background-color: rgb(28, 132, 153);
              }

              &:focus {
                outline: none;
              }
            }

            .mobile-sort-menu {
              position: fixed;
              box-sizing: border-box;
              padding: 10px;
              top: 120px;
              left: 100vw;
              border-radius: 0 0 5px 5px;
              background-color: #ffffff;
              min-width: 100vw;
              height: 120px;
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
              z-index: 999;

              .mobile-sort-btn {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;

                p {
                  width: 100%;
                }

                button {
                  margin: 5px;
                  width: 45%;
                  border-radius: 5px;
                  background-color: #e4e4e4;
                  align-items: center;
                  justify-content: center;
                  font-size: 16px;

                  &.selected {
                    color: white;
                    background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                  }
                }

                select {
                  margin-top: 14px;
                  height: fit-content;
                  width: 25%;
                  border-radius: 5px;
                  text-align-last: center;
                  text-align: center;
                  border: none;
                  background-color: transparent;
                  font-size: 16px;
                  font-weight: bold;
                  color: #00536D;
                  background-color: #e4e4e4;

                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }

          .btn-group {
            display: none;
          }
        }

        .card-wrapper {
          margin-top: 10px;
          margin-bottom: auto;
          width: 100%;
          max-height: calc(var(--vh, 1vh) * 100 - 175px);
          box-sizing: border-box;
          display: flex;
          flex-flow: row wrap;
          overflow-y: auto;
        }

        .inner-bar2 {
          display: inline-flex;
          width: 100%;
          justify-content: center;
          align-content: center;

          .btn-group {
            display: none;
            align-items: center;
          }

          .pagination {
            box-sizing: border-box;
            align-self: center;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);

            button {
              font-family: 'kanit', 'FontAwesome';
              margin: 0 2px;
              box-sizing: border-box;
              min-width: 30px;
              height: 30px;
              font-size: 16px;
              font-weight: bolder;
              border-radius: 5px;
              border: 0;
              color: #00536D;
              background-color: transparent;
              transition: all 100ms ease-in-out;
              cursor: pointer;

              &.selected {
                color: #009ece;
                background-color: rgba(255, 255, 255, 1);
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
              }

              &:disabled {
                color: rgba(0, 83, 109, 0.5);
                cursor: default;

                &:hover {
                  background-color: transparent;
                }
              }

              &:hover {
                background-color: rgb(255, 255, 255);
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}