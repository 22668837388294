//PC HD+
@media only screen and (min-width: 1366px) {
  .admin-card-container {
    display: flex;
    flex-flow: row wrap;
    border-radius: 10px;
    box-sizing: content-box;
    padding: 10px;
    width: calc(25% - 30px);
    height: 111px;
    margin: 5px;
    transition: all 100ms ease-in-out;
    justify-content: space-evenly;
    align-items: center;
    cursor: default;

    &.red {
      background-color: rgba(255, 174, 174, 0.7);

      &:hover {
        background-color: rgba(255, 218, 218, 0.9);
      }
    }

    &.green {
      background-color: rgba(187, 255, 174, 0.7);

      &:hover {
        background-color: rgba(230, 255, 218, 0.9);
      }
    }

    &.grey {
      background-color: rgba(190, 190, 190, 0.7);

      &:hover {
        background-color: rgba(226, 226, 226, 0.7);
      }
    }

    .company-logo {
      object-fit: cover;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      border: 5px solid rgba(39, 39, 39, 0.2);

      &.no-pic {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
      }
    }

    .details {
      display: flex;
      flex-flow: column wrap;
      width: 65%;
      justify-content: space-evenly;

      .name {
        width: 100%;
        color: #00536D;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .centering {
        width: 100%;
        align-self: center;
        justify-content: space-between;

        .diff {
          width: 50%;
          font-size: 16px;
          display: inline-flex;
          align-items: center;

          .l {
            display: inline-flex;
            align-items: center;
          }

          .r {
            display: none;
          }
        }

        .active {
          width: 50%;
          font-size: 16px;
          display: inline-flex;
          align-items: center;
        }

        .total {
          width: 50%;
          font-size: 16px;
          display: inline-flex;
          align-items: center;
        }

        .user {
          width: 50%;
          font-size: 16px;
          display: inline-flex;
          align-items: center;
        }
        .time{
          font-size: 12px;
          text-align: right;
          color: rgb(119, 119, 119)
        }
      }
    }
  }
}

//Below PC HD to iPad(L)
@media only screen and (max-width: 1365px) and (min-width: 768px) {
  .admin-card-container {
    display: flex;
    flex-flow: row wrap;
    border-radius: 10px;
    box-sizing: content-box;
    padding: 10px;
    width: calc(50% - 30px);
    height: 101px;
    background-color: rgba(255, 255, 255, 0.6);
    margin: 5px;
    transition: all 100ms ease-in-out;
    justify-content: space-evenly;
    cursor: default;
    align-items: center;

    &.red {
      background-color: rgba(255, 174, 174, 0.7);

      &:hover {
        background-color: rgba(255, 218, 218, 0.9);
      }
    }

    &.green {
      background-color: rgba(187, 255, 174, 0.7);

      &:hover {
        background-color: rgba(230, 255, 218, 0.9);
      }
    }

    &.grey {
      background-color: rgba(190, 190, 190, 0.7);

      &:hover {
        background-color: rgba(226, 226, 226, 0.7);
      }
    }

    .company-logo {
      object-fit: cover;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      border: 5px solid rgba(39, 39, 39, 0.2);

      &.no-pic {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
      }
    }

    .details {
      display: flex;
      flex-flow: column wrap;
      width: 65%;
      justify-content: space-evenly;

      .name {
        width: 100%;
        color: #00536D;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .centering {
        width: 100%;
        align-self: center;
        justify-content: space-between;

        .diff {
          width: 50%;
          font-size: 16px;
          display: inline-flex;
          align-items: center;

          .l {
            display: inline-flex;
            align-items: center;
          }

          .r {
            display: none;
          }
        }

        .active {
          width: 50%;
          font-size: 16px;
          display: inline-flex;
          align-items: center;
        }

        .total {
          width: 50%;
          font-size: 16px;
          display: inline-flex;
          align-items: center;
        }

        .user {
          width: 50%;
          font-size: 16px;
          display: inline-flex;
          align-items: center;
        }
        .time{
          font-size: 12px;
          text-align: right;
          color: rgb(119, 119, 119)
        }
      }
    }
  }
}

//All Phone(P, L)
@media only screen and (max-width: 767px) {
  .admin-card-container {
    display: flex;
    flex-flow: row wrap;
    border-radius: 10px;
    box-sizing: content-box;
    padding: 10px;
    width: 100%;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.6);
    margin: 5px 0;
    transition: all 100ms ease-in-out;
    justify-content: space-evenly;
    box-sizing: border-box;
    cursor: default;

    &.red {
      background-color: rgba(255, 174, 174, 0.7);

      &:hover {
        background-color: rgba(255, 218, 218, 0.9);
      }
    }

    &.green {
      background-color: rgba(187, 255, 174, 0.7);

      &:hover {
        background-color: rgba(230, 255, 218, 0.9);
      }
    }

    &.grey {
      background-color: rgba(190, 190, 190, 0.7);

      &:hover {
        background-color: rgba(226, 226, 226, 0.7);
      }
    }

    .company-logo {
      display: none;
      object-fit: cover;
      height: 80px;
      width: 80px;
      border-radius: 50%;

    }

    .details {
      display: flex;
      flex-flow: row wrap;
      box-sizing: border-box;
      padding: 5px;
      width: 100%;
      justify-content: space-evenly;

      .name-wrapper{
        flex: 5;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .name {
          color: #00536D;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .time{
          font-size: 12px;
          text-align: left;
          color: rgb(119, 119, 119)
        }
      }

      .centering {
        display: inline-flex;

        .diff {
          min-width: fit-content;
          font-size: 16px;
          display: inline-flex;
          align-items: center;
          text-align: right;

          .l {
            display: none;
          }

          .r {
            display: inline-flex;
            align-items: center;
          }
        }

        .active {
          display: none;
        }

        .total {
          display: none;
        }

        .user {
          display: none;
        }
      }
    }
  }
}