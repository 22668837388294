.UserNavbar{  
  // PC HD+
  @media only screen and (min-width: 1280px) {
    .nav-bar {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      height: 80px;
      width: 100%;
      background-color: white;
      box-shadow: 0 0 10px 0 #00000048;
      position: fixed;
      z-index: 999;
      top: 0;

      .mobile-tab-menu {
        display: none;
      }


      .logo-container {
        display: flex;
        flex-direction: row;
        margin-left: 2em;
        align-items: center;

        .bar {
          background-color: grey;
          width: 0.5px;
          height: 60px;
          margin: 0 20px;
        }

        img {
          max-height: 50px;
        }

        .annouce {
          margin-left: 10px;
          border: 0;
          min-width: 30px;
          height: 30px;
          font-size: 25px;
          align-self: center;
          justify-items: center;
          color: #494949;
          background-color: transparent;
          transition: ease-in-out 150ms;
          cursor: pointer;

          &:hover {
            color: #028AB6;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .menu {
        align-self: flex-end;
        margin-right: 2em;

        a {
          color: #272727;
          text-decoration: none;
          margin: 0 1em;

          &:hover {
            color: #028AB6;
          }
        }
      }

      .mobile-menu {
        display: none;

      }

      .annouceModal {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */

        .modal-content {
          @keyframes animatetop {
            from {
              top: 40%;
              opacity: 0
            }

            to {
              top: 35%;
              opacity: 1
            }
          }

          top: 35%;
          transform: translateY(-50%);
          position: relative;
          display: flex;
          flex-flow: column wrap;
          border-radius: 10px;
          background-color: #fefefe;
          margin: 3% auto;
          padding: 20px;
          border: 1px solid #888;
          width: fit-content;
          max-width: 1100px;
          box-shadow: 0 0 10px 0 #0000008c;
          animation-name: animatetop;
          animation-duration: 0.3s;


          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }

        .close {
          align-self: flex-end;
          margin-top: 10px;
          width: 100px;
          box-sizing: border-box;
          font-size: 16px;
          padding: 3px 10px;
          border: 0;
          border-radius: 5px;
          color: white;
          transition: all 200ms ease-in-out;
          background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 8px 1px rgb(69, 180, 195)
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  //PC qHD, iPad (P,L), iPhone X (L)
  @media only screen and (min-width: 768px) and (max-width: 1366px) {
    .nav-bar {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      height: 80px;
      width: 100%;
      background-color: white;
      box-shadow: 0 0 10px 0 #00000048;
      position: fixed;
      z-index: 999;
      top: 0;

      .logo-container {
        display: flex;
        flex-direction: row;
        margin-left: 2em;
        align-items: center;

        .bar {
          background-color: grey;
          width: 0.5px;
          height: 60px;
          margin: 0 20px;
        }

        img {
          max-height: 50px;
        }

        .annouce {
          margin-left: 10px;
          border: 0;
          min-width: 30px;
          height: 30px;
          font-size: 25px;
          align-self: center;
          justify-items: center;
          color: #494949;
          background-color: transparent;
          transition: ease-in-out 150ms;
          cursor: pointer;

          &:hover {
            color: #028AB6;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .menu {
        align-self: flex-end;
        margin-right: 2em;

        a {
          color: #272727;
          text-decoration: none;
          margin: 0 1em;

          &:hover {
            color: #028AB6;
          }
        }
      }

      .mobile-menu {
        display: none;
      }

      .mobile-tab-menu {
        display: none;
      }
      
      .annouceModal {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */

        .modal-content {
          @keyframes animatetop {
            from {
              top: 40%;
              opacity: 0
            }

            to {
              top: 35%;
              opacity: 1
            }
          }

          top: 35%;
          transform: translateY(-50%);
          position: relative;
          display: flex;
          flex-flow: column wrap;
          border-radius: 10px;
          background-color: #fefefe;
          margin: 5% auto;
          padding: 20px;
          border: 1px solid #888;
          width: fit-content;
          max-width: 1100px;
          box-shadow: 0 0 10px 0 #0000008c;
          animation-name: animatetop;
          animation-duration: 0.3s;


          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }

        .close {
          align-self: flex-end;
          margin-top: 10px;
          width: 100px;
          box-sizing: border-box;
          font-size: 16px;
          padding: 3px 10px;
          border: 0;
          border-radius: 5px;
          color: white;
          transition: all 200ms ease-in-out;
          background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 8px 1px rgb(69, 180, 195)
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  //Phone Portrait
  @media only screen and (max-width: 767px) {
    .nav-bar {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      height: 60px;
      width: 100%;
      background-color: white;
      box-shadow: 0 0 10px 0 #00000048;
      position: fixed;
      z-index: 999;
      top: 0;

      .logo-container {
        display: flex;
        flex-direction: row;
        margin-left: 1em;
        align-items: center;
        align-self: flex-start;
        object-fit: scale-down;
        max-width: 270px;

        .bar {
          background-color: grey;
          width: 0.5px;
          height: 40px;
          margin: 0 5px;
        }

        img {
          max-height: 40px;
        }

        .annouce {
          margin-left: 10px;
          border: 0;
          min-width: 30px;
          height: 30px;
          font-size: 25px;
          align-self: center;
          justify-items: center;
          color: #494949;
          background-color: transparent;
          transition: ease-in-out 150ms;
          cursor: pointer;

          &:hover {
            color: #028AB6;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .menu {
        display: none;
        align-self: flex-end;
        margin-right: 2em;

        a {
          color: #272727;
          text-decoration: none;
          margin: 0 1em;

          &:hover {
            color: #028AB6;
          }
        }
      }

      .mobile-menu {
        align-self: flex-end;
        margin-right: 1em;

        button {
          display: flex;
          border: none;
          background-color: white;

          &:focus {
            outline: none;
          }
        }

        .menu-content {
          z-index: 1;
          flex-flow: column wrap;
          box-sizing: border-box;
          position: fixed;
          background-color: white;
          width: 100%;
          left: 0%;
          top: 60px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

          a {
            text-decoration: none;
            color: #494949;
            padding: 10px 10px;
            border-top: solid 0.5px #adadad;
          }
        }
      }

      .annouceModal {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */

        .modal-content {
          @keyframes animatetop {
            from {
              top: 40%;
              opacity: 0
            }

            to {
              top: 35%;
              opacity: 1
            }
          }

          top: 35%;
          transform: translateY(-50%);
          position: relative;
          display: flex;
          flex-flow: column wrap;
          border-radius: 10px;
          background-color: #fefefe;
          margin: 5% auto;
          padding: 10px;
          border: 1px solid #888;
          width: fit-content;
          max-width: 1100px;
          box-shadow: 0 0 10px 0 #0000008c;
          animation-name: animatetop;
          animation-duration: 0.3s;


          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }

        .close {
          align-self: flex-end;
          margin-top: 10px;
          width: 100px;
          box-sizing: border-box;
          font-size: 16px;
          padding: 3px 10px;
          border: 0;
          border-radius: 5px;
          color: white;
          transition: all 200ms ease-in-out;
          background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 8px 1px rgb(69, 180, 195)
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  // iPhone 4
  @media only screen and (max-width: 359px) {}
}