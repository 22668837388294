.Manage {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D65E5E;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2.5px;
    bottom: 2px;
    background-color: white;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked+.slider {
    background-color: #5ED66A;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #5ED66A;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  //PC HD+
  @media only screen and (min-width: 1366px) {
    .container {
      position: absolute;
      top: 80px;
      width: 100%;
      height: calc(100% - 80px);
    }

    .wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 98%;
      height: 97%;
    }

    .summary {
      display: flex;
      flex-flow: row wrap;
      float: left;
      position: relative;
      width: 24.5%;
      height: 100%;
      margin-right: 1%;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      justify-content: space-between;

      .users {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        height: calc(25% - 10px);
        background-color: #F2F2F2;
        border-radius: 10px;
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 1vw;
        }

        h1 {
          font-size: 3vw;
        }
      }

      .waiting {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        height: calc(25% - 10px);
        background-color: #AFF7FC;
        border-radius: 10px;
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 1vw;
        }

        h1 {
          font-size: 3vw;
        }
      }

      .account {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        height: calc(25% - 10px);
        background-color: #D9FDD1;
        border-radius: 10px;
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 1vw;
        }

        h1 {
          font-size: 3vw;
        }
      }

      .closed {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        height: calc(25% - 10px);
        background-color: #FFE0E0;
        border-radius: 10px;
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 1vw;
        }

        h1 {
          font-size: 3vw;
        }
      }
    }

    .list-wrapper {
      display: flex;
      flex-flow: column nowrap;
      float: left;
      position: relative;
      width: 74.5%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .button-wrapper {
        height: 5%;
        margin-bottom: -1px;

        button {
          height: 100%;
          border: none;
          box-sizing: border-box;
          padding: 5px 10px;
          background-color: rgb(180, 180, 180);
          border-radius: 10px 10px 0 0;
          cursor: pointer;
          transition: ease-in-out 150ms;

          &.selected {
            background-color: #F4F4F4;

            &:hover {
              background-color: #F4F4F4;
            }
          }

          &:hover {
            background-color: #dfdfdf;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .inner-wrap {
        max-height: fit-content;
        width: 100%;
        box-sizing: border-box;
        border-radius: 0 10px 10px 10px;
        padding: 10px;
        background-color: #F4F4F4;

        .inner-list-wrap {
          max-width: 100%;
          max-height: fit-content;
          display: flex;
          flex-flow: column wrap;
          overflow-x: hidden;
          overflow-y: auto;

          li {
            display: flex;
            list-style-type: none;
            border-bottom: solid 1px rgb(128, 128, 128);
            margin-bottom: 5px;
            height: 40px;
            width: 100%;
            border-radius: 5px 5px 0 0;

            &:hover {
              background-color: white;
            }
          }
        }

        li {
          display: flex;
          list-style-type: none;
          border-bottom: solid 1px rgb(128, 128, 128);
          margin-bottom: 5px;

          div {
            display: inline;

            a {
              display: inline-flex;
              align-items: center;
              text-decoration: none;
              color: #434343;
              cursor: pointer;
            }
          }

          .id {
            flex: 1;
            padding: 5px;
            border-right: solid 1px rgb(128, 128, 128);
            text-align: center;
          }

          .email {
            flex: 3;
            padding: 5px;
            border-right: solid 1px rgb(128, 128, 128);
          }

          .company {
            flex: 4;
            padding: 5px;
            border-right: solid 1px rgb(128, 128, 128);
          }

          .branch {
            flex: 2;
            padding: 5px;
            border-right: solid 1px rgb(128, 128, 128);
            text-align: center;
          }

          .status {
            flex: 1;
            padding: 5px;
            text-align: center;

            .accept {
              border: none;
              background-color: transparent;
              color: rgb(112, 112, 112);
              font-size: 22px;
              cursor: pointer;
              transition: ease-in-out 150ms;

              &:hover {
                color: rgb(0, 226, 0);
              }

              &:focus {
                outline: none;
              }
            }

            .reject {
              margin-left: 10px;
              border: none;
              background-color: transparent;
              color: rgb(112, 112, 112);
              font-size: 22px;
              cursor: pointer;
              transition: ease-in-out 150ms;

              &:hover {
                color: rgb(226, 0, 0);
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  //Below PC HD to iPad
  @media only screen and (max-width: 1365px) and (min-width: 768px) {
    .container {
      position: absolute;
      top: 80px;
      width: 100%;
      height: calc(100% - 80px);
    }

    .wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 98%;
      height: 97%;
    }

    .summary {
      display: flex;
      flex-flow: row wrap;
      float: left;
      position: relative;
      width: 24.5%;
      height: 100%;
      margin-right: 1%;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      justify-content: space-between;

      .users {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        height: calc(25% - 10px);
        background-color: #F2F2F2;
        border-radius: 10px;
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 2vw;
        }

        h1 {
          font-size: 5vw;
        }
      }

      .waiting {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        height: calc(25% - 10px);
        background-color: #AFF7FC;
        border-radius: 10px;
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 2vw;
        }

        h1 {
          font-size: 5vw;
        }
      }

      .account {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        height: calc(25% - 10px);
        background-color: #D9FDD1;
        border-radius: 10px;
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 2vw;
        }

        h1 {
          font-size: 5vw;
        }
      }

      .closed {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        height: calc(25% - 10px);
        background-color: #FFE0E0;
        border-radius: 10px;
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 2vw;
        }

        h1 {
          font-size: 5vw;
        }
      }
    }

    .list-wrapper {
      display: flex;
      flex-flow: column nowrap;
      float: left;
      position: relative;
      width: 74.5%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .button-wrapper {
        min-height: fit-content;
        max-height: 5%;
        margin-bottom: -1px;

        button {
          height: 100%;
          border: none;
          box-sizing: border-box;
          padding: 5px 10px;
          background-color: rgb(180, 180, 180);
          border-radius: 10px 10px 0 0;
          cursor: pointer;

          &.selected {
            background-color: #F4F4F4;

            &:hover {
              background-color: #F4F4F4;
            }
          }

          &:hover {
            background-color: #dfdfdf;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .inner-wrap {
        max-height: fit-content;
        width: 100%;
        box-sizing: border-box;
        border-radius: 0 10px 10px 10px;
        padding: 10px;
        background-color: #F4F4F4;

        .inner-list-wrap {
          max-width: 100%;
          max-height: fit-content;
          display: flex;
          flex-flow: column wrap;
          overflow-x: hidden;
          overflow-y: auto;

          li {
            display: flex;
            list-style-type: none;
            border-bottom: solid 1px rgb(128, 128, 128);
            margin-bottom: 5px;
            height: 40px;
            width: 100%;
            border-radius: 5px 5px 0 0;

            &:hover {
              background-color: white;
            }
          }
        }

        li {
          display: flex;
          list-style-type: none;
          border-bottom: solid 1px rgb(128, 128, 128);
          margin-bottom: 5px;

          div {
            display: inline;

            a {
              display: inline-flex;
              align-items: center;
              text-decoration: none;
              color: #434343;
              cursor: pointer;
            }
          }

          .id {
            flex: 1;
            padding: 5px;
            border-right: solid 1px rgb(128, 128, 128);
            text-align: center;
          }

          .email {
            flex: 3;
            padding: 5px;
            border-right: solid 1px rgb(128, 128, 128);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .company {
            flex: 4;
            padding: 5px;
            border-right: solid 1px rgb(128, 128, 128);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .branch {
            flex: 2;
            padding: 5px;
            border-right: solid 1px rgb(128, 128, 128);
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .status {
            flex: 1;
            padding: 5px;
            text-align: center;

            .accept {
              border: none;
              background-color: transparent;
              color: rgb(112, 112, 112);
              font-size: 22px;
              cursor: pointer;
              transition: ease-in-out 150ms;

              &:hover {
                color: rgb(0, 226, 0);
              }

              &:focus {
                outline: none;
              }
            }

            .reject {
              margin-left: 10px;
              border: none;
              background-color: transparent;
              color: rgb(112, 112, 112);
              font-size: 22px;
              cursor: pointer;
              transition: ease-in-out 150ms;

              &:hover {
                color: rgb(226, 0, 0);
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }

  //All Phone(P, L)
  @media only screen and (max-width: 1000px) and (max-height: 1023px) and (min-height: 411px) {
    .container {
      position: absolute;
      top: 60px;
      width: 100%;
      height: calc(100% - 60px);
    }

    .wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 97%;
      height: 97%;
    }

    .summary {
      display: flex;
      flex-flow: row wrap;
      position: relative;
      width: 100%;
      height: 23%;
      overflow-y: hidden;
      overflow-x: hidden;

      .users {
        display: flex;
        flex-flow: row wrap;
        width: calc(50% - 10px);
        height: calc(50% - 10px);
        margin: 5px;
        background-color: #F2F2F2;
        border-radius: 5px;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 13px;
        }

        h1 {
          font-size: 18px;
        }
      }

      .waiting {
        display: flex;
        flex-flow: row wrap;
        width: calc(50% - 10px);
        height: calc(50% - 10px);
        margin: 5px;
        background-color: #AFF7FC;
        border-radius: 5px;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 13px;
        }

        h1 {
          font-size: 18px;
        }
      }

      .account {
        display: flex;
        flex-flow: row wrap;
        width: calc(50% - 10px);
        height: calc(50% - 10px);
        margin: 5px;
        background-color: #D9FDD1;
        border-radius: 5px;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 13px;
        }

        h1 {
          font-size: 18px;
        }
      }

      .closed {
        display: flex;
        flex-flow: row wrap;
        width: calc(50% - 10px);
        height: calc(50% - 10px);
        margin: 5px;
        background-color: #FFE0E0;
        border-radius: 5px;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
        justify-content: center;
        align-items: center;

        h4 {
          width: 100%;
          text-align: center;
          font-size: 13px;
        }

        h1 {
          font-size: 18px;
        }
      }
    }

    .list-wrapper {
      position: relative;
      width: 100%;
      height: 77%;
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;

      .button-wrapper {
        display: inline-flex;
        flex-flow: row wrap;
        background-color: #F2F2F2;
        width: 100%;
        height: fit-content;
        justify-content: space-evenly;
        border-radius: 5px;
        margin: 5px 0;

        button {
          height: 30px;
          width: calc(50% - 10px);
          background-color: transparent;
          border-radius: 5px;
          border: none;
          margin: 5px;
          font-size: 14px;

          &:focus {
            outline: none;
          }

          &.selected {
            background-color: white;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
          }
        }
      }

      .inner-wrap {
        max-height: fit-content;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px;
        background-color: #F4F4F4;

        .inner-list-wrap {
          max-width: 100%;
          max-height: fit-content;
          display: flex;
          flex-flow: column wrap;
          overflow-x: hidden;
          overflow-y: auto;

          li {
            display: flex;
            list-style-type: none;
            border-bottom: solid 1px rgb(128, 128, 128);
            margin-bottom: 5px;
            height: 40px;
            width: 100%;
            border-radius: 5px 5px 0 0;

            &:hover {
              background-color: white;
            }
          }
        }

        li {
          display: flex;
          list-style-type: none;
          border-bottom: solid 1px rgb(128, 128, 128);
          margin-bottom: 5px;

          div {
            display: inline;

            a {
              display: inline-flex;
              align-items: center;
              text-decoration: none;
              color: #434343;
              cursor: pointer;
            }
          }

          .id {
            flex: 1;
            padding: 5px;
            text-align: center;
          }

          .email {
            flex: 5;
            padding: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .company {
            display: none;
          }

          .branch {
            display: none;
            padding: 5px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .status {
            flex: 1;
            padding: 5px;
            text-align: center;

            .accept {
              border: none;
              background-color: transparent;
              color: rgb(112, 112, 112);
              font-size: 22px;
              cursor: pointer;
              transition: ease-in-out 150ms;

              &:hover {
                color: rgb(0, 226, 0);
              }

              &:focus {
                outline: none;
              }
            }

            .reject {
              margin-left: 10px;
              border: none;
              background-color: transparent;
              color: rgb(112, 112, 112);
              font-size: 22px;
              cursor: pointer;
              transition: ease-in-out 150ms;

              &:hover {
                color: rgb(226, 0, 0);
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}