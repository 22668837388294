.AdminNavbar{
  // PC HD+
  @media only screen and (min-width: 1280px) {
    .nav-bar {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      height: 80px;
      width: 100%;
      background-color: white;
      box-shadow: 0 0 10px 0 #00000048;
      position: fixed;
      z-index: 999;
      top: 0;

      .mobile-tab-menu {
        display: none;
      }


      .logo-container {
        display: flex;
        flex-direction: row;
        margin-left: 2em;
        align-items: center;

        .bar {
          background-color: grey;
          width: 0.5px;
          height: 60px;
          margin: 0 20px;
        }

        img {
          max-height: 50px;
        }
      }

      .menu {
        display: inline-flex;
        align-self: flex-end;
        margin-right: 2em;
        justify-content: space-between;
        width: 450px;

        a {
          color: #272727;
          text-decoration: none;

          &:hover {
            color: #028AB6;
          }
        }
      }

      .mobile-menu {
        display: none;
      }
    }
  }

  //PC qHD, iPad (P,L), iPhone X (L)
  @media only screen and (min-width: 768px) and (max-width: 1366px) {
    .nav-bar {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      height: 80px;
      width: 100%;
      background-color: white;
      // background-color: teal;
      box-shadow: 0 0 10px 0 #00000048;
      position: fixed;
      z-index: 999;
      top: 0;

      .logo-container {
        display: flex;
        flex-direction: row;
        margin-left: 2em;
        align-items: center;

        .bar {
          background-color: grey;
          width: 0.5px;
          height: 60px;
          margin: 0 20px;
        }

        img {
          max-height: 50px;
        }
      }

      .menu {
        display: inline-flex;
        align-self: flex-end;
        margin-right: 2em;
        justify-content: space-between;
        width: 370px;

        a {
          color: #272727;
          text-decoration: none;
          font-size: 14px;
          // margin: 0 1em;

          &:hover {
            color: #028AB6;
          }
        }
      }
      
      .mobile-menu {
        display: none;
      }
    }
  }

  //Phone Portrait
  @media only screen and (max-width: 767px) {
    .nav-bar {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      height: 60px;
      width: 100%;
      background-color: white;
      box-shadow: 0 0 10px 0 #00000048;
      position: fixed;
      z-index: 999;
      top: 0;


      .logo-container {
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        align-items: center;
        align-self: flex-start;
        object-fit: scale-down;
        max-width: 270px;

        .bar {
          background-color: grey;
          width: 0.5px;
          height: 40px;
          margin: 0 5px;
        }

        img {
          max-height: 40px;
        }
      }

      .menu {
        display: none;
        align-self: flex-end;
        margin-right: 2em;

        a {
          color: #272727;
          text-decoration: none;
          margin: 0 1em;

          &:hover {
            color: #028AB6;
          }
        }
      }

      .mobile-menu {
        align-self: flex-end;
        margin-right: 1em;

        button {
          display: flex;
          border: none;
          background-color: white;

          &:focus {
            outline: none;
          }
        }

        .menu-content {
          z-index: 1;
          flex-flow: column wrap;
          box-sizing: border-box;
          position: fixed;
          background-color: white;
          width: 100%;
          left: 0%;
          top: 60px;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

          a {
            text-decoration: none;
            color: #494949;
            padding: 10px 10px;
            border-top: solid 0.5px #adadad;
          }
        }
      }
    }
  }

  // iPhone 4
  @media only screen and (max-width: 359px) {
    .nav-bar{
      height: 50px;
    }
  }
}