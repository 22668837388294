//PC HD+
@media only screen and (min-width: 1366px) {
  .card-container {
    display: flex;
    flex-flow: row wrap;
    border-radius: 10px;
    box-sizing: content-box;
    padding: 10px;
    width: calc(25% - 30px);
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.6);
    margin: 5px;
    transition: all 100ms ease-in-out;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }

    &.red {
      background-color: rgba(255, 174, 174, 0.7);

      &:hover {
        background-color: rgba(255, 218, 218, 0.9);
      }
    }

    .profile-pic {
      object-fit: cover;
      height: 72px;
      width: 72px;
      border: 5px solid rgb(230, 250, 255);
      border-radius: 50%;
      color: white;

      &.no-pic {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: linear-gradient(180deg, rgba(65, 109, 122, 1) 0%, rgba(0, 21, 37, 1) 100%);
      }
    }

    .details {
      display: flex;
      flex-flow: column wrap;
      padding-left: 5px;
      // flex: 1;
      width: 115px;
      justify-content: space-evenly;

      .username {
        width: 100%;
        color: #00536D;
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .centering {
        display: flex;
        flex-flow: row wrap;

        .calls {
          font-size: 19.8px;
          display: inline-flex;
          align-items: center;
        }

        .diff {
          font-size: 19.8px;
          font-weight: lighter;
          display: inline-flex;
          align-items: center;

          &.red {
            color: red;
          }

          &.green {
            color: #00860b;
          }
        }
      }

      .last-update {
        font-size: 10.8px;
        display: inline-flex;
        align-items: center;
      }
    }

    .details2 {
      // padding: 5px 0 0 0;
      display: flex;
      flex-flow: column wrap;
      flex: 1;
      align-items: center;

      .c{
        font-size: 18px;
        font-weight: 600;
        width: 2.5rem;
        // text-align: center;
        line-height: 2rem;
      }

      .r{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }
    }
    .details3 {
      // padding: 5px 0 0 0;
      display: flex;
      flex-flow: row wrap;
      flex: 1;
      align-items: center;

      .c{
        font-size: 18px;
        font-weight: 600;
        width: calc((100% - 27px) / 2);
        // text-align: center;
        line-height: 2rem;
      }
      .filler{
        width: 27px;
      }
    }
  }
}

//Below PC HD to iPad(L)
@media only screen and (max-width: 1365px) and (min-width: 1261px) {
  .card-container {
    display: flex;
    flex-flow: row wrap;
    border-radius: 10px;
    box-sizing: content-box;
    padding: 10px;
    width: calc(33% - 30px);
    // height: 101px;
    background-color: rgba(255, 255, 255, 0.6);
    margin: 5px;
    transition: all 100ms ease-in-out;
    justify-content: space-evenly;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }

    &.red {
      background-color: rgba(255, 174, 174, 0.7);

      &:hover {
        background-color: rgba(255, 218, 218, 0.9);
      }
    }

    .profile-pic {
      object-fit: cover;
      height: 90px;
      width: 90px;
      border: 5px solid rgb(230, 250, 255);
      border-radius: 50%;
      color: white;

      &.no-pic {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: linear-gradient(180deg, rgba(65, 109, 122, 1) 0%, rgba(0, 21, 37, 1) 100%);
      }
    }

    .details {
      display: flex;
      flex-flow: column wrap;
      width: 60%;
      justify-content: space-evenly;

      .username {
        color: #00536D;
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .centering {
        display: flex;
        flex-flow: row wrap;

        .calls {
          font-size: 24px;
          display: inline-flex;
          align-items: center;
        }

        .diff {
          font-size: 24px;
          font-weight: lighter;
          display: inline-flex;
          align-items: center;

          &.red {
            color: red;
          }

          &.green {
            color: #00860b;
          }
        }
      }

      .last-update {
        font-size: 14px;
        display: inline-flex;
        align-items: center;
      }
    }
    
    .details3 {
      // padding: 5px 0 0 0;
      display: flex;
      flex-flow: row wrap;
      flex: 1;
      align-items: center;

      .c{
        font-size: 1.4vw;
        font-weight: 600;
        width: calc((100% - 34px) / 2);
        // text-align: center;
        line-height: 2rem;
      }
      .filler{
        width: 34px;
      }
    }
  }
}

//All Phone(P, L)
@media only screen and (max-width: 1260px) {
  .card-container {
    display: flex;
    flex-flow: row wrap;
    border-radius: 10px;
    box-sizing: content-box;
    padding: 10px;
    width: calc(33vw - 37px);
    // max-height: 151px;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.6);
    margin: 0.5%;
    transition: all 100ms ease-in-out;
    justify-content: space-evenly;
    cursor: pointer;

    @media only screen and (max-width: 998px) {
      width: 28%;
    }
    @media only screen and (max-width: 812px) {
      width: 27%;
    }
    @media only screen and (max-width: 409px) {
      width: 25%;
    }

    @media only screen and (max-width: 360px) {
      // font-size: 13px;
      // padding: 4px;
      width: calc(50% - 25px);
    }

    @media only screen and (min-width: 999px) {
      width: calc(33% - 30px);
      margin: 5px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }

    &.red {
      background-color: rgba(255, 174, 174, 0.7);

      &:hover {
        background-color: rgba(255, 218, 218, 0.9);
      }
    }

    .profile-pic {
      display: none;
      object-fit: cover;
      height: 70px;
      width: 70px;
      border: 3px solid rgb(230, 250, 255);
      border-radius: 50%;
      color: white;

      @media only screen and (max-width: 320px) {
        height: 60px;
        width: 60px;
      }

      &.no-pic {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: linear-gradient(180deg, rgba(65, 109, 122, 1) 0%, rgba(0, 21, 37, 1) 100%);
      }
    }

    .details {
      height: fit-content;
      display: flex;
      flex-flow: column wrap;
      width: 100%;
      justify-content: space-evenly;

      .username {
        width: 100%;
        color: #00536D;
        font-size: 15px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .centering {
        display: flex;
        flex-flow: row wrap;
        width: fit-content;
        align-self: center;

        .calls {
          width: fit-content;
          font-size: 17px;
          display: inline-flex;
          align-items: center;
          @media only screen and (min-width: 400px) {
            font-size: 19px;
          }
          @media only screen and (min-width: 768px) {
            font-size: 21px;
          }
        }

        .diff {
          font-size: 16px;
          font-weight: 100;
          display: inline-flex;
          align-items: center;
          @media only screen and (min-width: 400px) {
            font-size: 19px;
          }
          @media only screen and (min-width: 768px) {
            font-size: 21px;
          }

          &.red {
            color: red;
          }

          &.green {
            color: #00860b;
          }
        }
      }

      .last-update {
        width: 100%;
        font-size: 9px;
        text-align: center;
        align-items: center;

        @media only screen and (max-width: 380px) {
          font-size: 7px;
        }
      }
    }
    .details3 {
      // padding: 5px 0 0 0;
      display: flex;
      flex-flow: row wrap;
      flex: 1;
      justify-content: center;

      .c{
        font-size: 14px;
        @media only screen and (min-width: 400px) {
          font-size: 16px;
        }
        @media only screen and (min-width: 768px) {
          font-size: 18px;
        }
        font-weight: 600;
        width: 2.5rem;
        text-align: center;
        line-height: 2rem;
      }
    }
  }
}