@font-face {
    font-family: "kanit";
    src: url("./assets/fonts/Kanit/Kanit-Regular.ttf");
}

* {
    font-family: 'kanit';

    div:not(.exception) {
        color: #3b3b3b;
    }

    margin: 0;
    padding: 0;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
    supported by Chrome, Opera and Firefox */
}

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    border-radius: 20px;

    &:hover {
        background: rgba(0, 0, 0, 0.2);
    }
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.6);
    }
}



.rotate{
    display: none;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    z-index: 1000;
    background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);

    @media screen and (orientation: landscape){
        display: block;
    }

    .phone{
        position: absolute;
        width: fit-content;
        height: fit-content;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
    }

    .notice{
        color: white;
        left: 50%;
        top: 65%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-size: 24px;
        @media screen and (max-width: 520px){
            font-size: 20px;
        }
    }
}