.Login{
    .bgWrapper {
        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        background-image: url("../../../assets/background/bg.jpg");
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        overflow: hidden;
    }

    @media only screen and (min-width: 1366px) {
        .container {
            position: absolute;
            background-color: white;
            width: 35%;
            min-width: 528px;
            height: calc(var(--vh, 1vh) * 100);
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
        }
        
        .wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
        
            * {
                position: relative;
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
            }

            p {
                opacity: 0;
                color: rgba(255, 0, 0, 0.534);
                text-align: right;
                width: 98%;
                font-size: 14px;
                margin-top: 4px;
                &.show{
                    opacity: 1;
                }
            }
        
            .title {
                text-align: center;
                font-size: 48px;
                margin-bottom: 15px;
            }
        
            .email {
                margin-top: 0;
                width: calc(100% - 15px);
                height: 55px;
                border: none;
                outline: none;
                background-color: #EEEEEE;
                color: #6B6B6B;
                font-size: 18px;
                padding-left: 15px;
                &.alert{
                    width: calc(100% - 17px);
                    height: 53px;
                    border: 1px solid rgba(255, 0, 0, 0.534);
                    color: rgba(255, 0, 0, 0.534);
                    background-color: rgba(255, 0, 0, 0.219);
                }
                &.pass{
                    width: calc(100% - 17px);
                    height: 53px;
                }
            }
        
            .password {
                margin-top: 0;
                width: calc(100% - 15px);
                height: 55px;
                border: none;
                outline: none;
                background-color: #EEEEEE;
                color: #6B6B6B;
                font-size: 18px;
                padding-left: 15px;
                &.alert{
                    width: calc(100% - 17px);
                    height: 53px;
                    border: 1px solid rgba(255, 0, 0, 0.534);
                    color: rgba(255, 0, 0, 0.534);
                    background-color: rgba(255, 0, 0, 0.219);
                }
                &.pass{
                    width: calc(100% - 17px);
                    height: 53px;
                }
            }
        
            .login-btn {
                height: 44px;
                border: none;
                outline: none;
                transition: all 200ms ease-in-out;
                cursor: pointer;
        
                &:hover {
                    box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
                }
        
                background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                color: white;
                font-size: 24px;
            }
        
            .remember {
                // display  : flex;
                position: relative;
                // align-items: center;
                margin-top: 5px;
                margin-bottom: 30px;
                width: 100%;
                height: 24px;
                // background-color: teal;
        
                div {
                    position: relative;
                    float: left;
                    margin-top: 0;
                    width: 20px;
                    height: 24px;
                    // background-color: red;
                    margin-right: 0.5em;
                }
        
                .remember-me {
                    border: 2px solid rgba(0, 137, 181, 1);
        
                    color: transparent !important;
                    margin-top: 2px;
                    height: 20px;
                    width: 20px;
        
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    outline: none;
                    content: none;
                }
        
                .remember-me:checked {
                    border: 2px solid rgba(0, 137, 181, 1);
                    background-color: rgb(34, 144, 180);
                    color: transparent !important;
                    margin-top: 2px;
                    height: 20px;
                    width: 20px;
        
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    outline: none;
                    content: none;
                }
        
                .forget-btn {
                    width: 90px;
                    float: right;
                    margin-top: 0;
        
                    .text {
                        color: rgba(0, 137, 181, 1);
                        text-decoration: none;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        
            .register {
                opacity: 1;
                font-size: 16px;
                margin-top: 0;
                text-align: center;
                color: #454545;
        
                a {
                    color: #454545;
                    font-weight: bold;
                    text-decoration: none;
        
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1366px) and (min-height: 480px){
        .container {
            position: absolute;
            background-color: white;
            width: 50%;
            height: calc(var(--vh, 1vh) * 100);
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
        }
        
        .wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            // background-color: green;
            width: 80%;
        
            * {
                position: relative;
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
            }

            p {
                opacity: 0;
                color: rgba(255, 0, 0, 0.534);
                text-align: right;
                width: 98%;
                font-size: 14px;
                margin-top: 4px;
                &.show{
                    opacity: 1;
                }
            }
        
            .title {
                text-align: center;
                font-size: 42px;
                margin-bottom: 15px;
            }
        
            .email {
                margin-top: 0;
                width: calc(100% - 15px);
                height: 55px;
                border: none;
                outline: none;
                background-color: #EEEEEE;
                color: #6B6B6B;
                font-size: 18px;
                padding-left: 15px;
                &.alert{
                    width: calc(100% - 17px);
                    height: 53px;
                    border: 1px solid rgba(255, 0, 0, 0.534);
                    color: rgba(255, 0, 0, 0.534);
                    background-color: rgba(255, 0, 0, 0.219);
                }
                &.pass{
                    width: calc(100% - 17px);
                    height: 53px;
                }
            }
        
            .password {
                margin-top: 0;
                width: calc(100% - 15px);
                height: 55px;
                border: none;
                outline: none;
                background-color: #EEEEEE;
                color: #6B6B6B;
                font-size: 18px;
                padding-left: 15px;
                &.alert{
                    width: calc(100% - 17px);
                    height: 53px;
                    border: 1px solid rgba(255, 0, 0, 0.534);
                    color: rgba(255, 0, 0, 0.534);
                    background-color: rgba(255, 0, 0, 0.219);
                }
                &.pass{
                    width: calc(100% - 17px);
                    height: 53px;
                }
            }
        
            .login-btn {
                height: 44px;
                border: none;
                outline: none;
                transition: all 200ms ease-in-out;
                cursor: pointer;
        
                &:hover {
                    box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
                }
        
                background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                color: white;
                font-size: 24px;
            }
        
            .remember {
                // display  : flex;
                position: relative;
                // align-items: center;
                margin-top: 5px;
                margin-bottom: 30px;
                width: 100%;
                height: 24px;
                // background-color: teal;
        
                div {
                    position: relative;
                    float: left;
                    margin-top: 0;
                    width: 20px;
                    height: 24px;
                    // background-color: red;
                    margin-right: 0.5em;
                }
        
                .remember-me {
                    border: 2px solid rgba(0, 137, 181, 1);
        
                    color: transparent !important;
                    margin-top: 2px;
                    height: 20px;
                    width: 20px;
        
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    outline: none;
                    content: none;
                }
        
                .remember-me:checked {
                    border: 2px solid rgba(0, 137, 181, 1);
                    background-color: rgb(34, 144, 180);
                    color: transparent !important;
                    margin-top: 2px;
                    height: 20px;
                    width: 20px;
        
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    outline: none;
                    content: none;
                }
        
                .forget-btn {
                    width: 90px;
                    float: right;
                    margin-top: 0;
        
                    .text {
                        color: rgba(0, 137, 181, 1);
                        text-decoration: none;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        
            .register {
                opacity: 1;
                font-size: 16px;
                margin-top: 0;
                text-align: center;
                color: #454545;
        
                a {
                    color: #454545;
                    font-weight: bold;
                    text-decoration: none;
        
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1366px) and (max-height: 479px){
        .bgWrapper {
            height: 120vh;
        }
        
        .container {
            position: absolute;
            background-color: white;
            width: 50%;
            height: 120vh;
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
        }
        
        .wrapper {
            position: absolute;
            left: 50%;
            top: 47.5%;
            transform: translate(-50%, -50%);
            // background-color: green;
            width: 80%;
        
            * {
                position: relative;
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
            }

            p {
                opacity: 0;
                color: rgba(255, 0, 0, 0.534);
                text-align: right;
                width: 98%;
                font-size: 14px;
                margin-top: 4px;
                &.show{
                    opacity: 1;
                }
            }
        
            .title {
                text-align: center;
                font-size: 42px;
                margin-bottom: 15px;
            }
        
            .email {
                margin-top: 0;
                width: calc(100% - 15px);
                height: 55px;
                border: none;
                outline: none;
                background-color: #EEEEEE;
                color: #6B6B6B;
                font-size: 18px;
                padding-left: 15px;
                &.alert{
                    width: calc(100% - 17px);
                    height: 53px;
                    border: 1px solid rgba(255, 0, 0, 0.534);
                    color: rgba(255, 0, 0, 0.534);
                    background-color: rgba(255, 0, 0, 0.219);
                }
                &.pass{
                    width: calc(100% - 17px);
                    height: 53px;
                }
            }
        
            .password {
                margin-top: 0;
                width: calc(100% - 15px);
                height: 55px;
                border: none;
                outline: none;
                background-color: #EEEEEE;
                color: #6B6B6B;
                font-size: 18px;
                padding-left: 15px;
                &.alert{
                    width: calc(100% - 17px);
                    height: 53px;
                    border: 1px solid rgba(255, 0, 0, 0.534);
                    color: rgba(255, 0, 0, 0.534);
                    background-color: rgba(255, 0, 0, 0.219);
                }
                &.pass{
                    width: calc(100% - 17px);
                    height: 53px;
                }
            }
        
            .login-btn {
                height: 44px;
                border: none;
                outline: none;
                transition: all 200ms ease-in-out;
                cursor: pointer;
        
                &:hover {
                    box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
                }
        
                background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                color: white;
                font-size: 24px;
            }
        
            .remember {
                // display  : flex;
                position: relative;
                // align-items: center;
                margin-top: 5px;
                margin-bottom: 30px;
                width: 100%;
                height: 24px;
                // background-color: teal;
        
                div {
                    position: relative;
                    float: left;
                    margin-top: 0;
                    width: 20px;
                    height: 24px;
                    // background-color: red;
                    margin-right: 0.5em;
                }
        
                .remember-me {
                    border: 2px solid rgba(0, 137, 181, 1);
        
                    color: transparent !important;
                    margin-top: 2px;
                    height: 20px;
                    width: 20px;
        
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    outline: none;
                    content: none;
                }
        
                .remember-me:checked {
                    border: 2px solid rgba(0, 137, 181, 1);
                    background-color: rgb(34, 144, 180);
                    color: transparent !important;
                    margin-top: 2px;
                    height: 20px;
                    width: 20px;
        
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    outline: none;
                    content: none;
                }
        
                .forget-btn {
                    width: 90px;
                    float: right;
                    margin-top: 0;
        
                    .text {
                        color: rgba(0, 137, 181, 1);
                        text-decoration: none;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        
            .register {
                opacity: 1;
                font-size: 16px;
                margin-top: 0;
                text-align: center;
                color: #454545;
        
                a {
                    color: #454545;
                    font-weight: bold;
                    text-decoration: none;
        
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) and (min-height: 480px){
        .container {
            position: absolute;
            background-color: white;
            width: 100%;
            height: calc(var(--vh, 1vh) * 100);
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
        }
        
        .wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            // background-color: green;
            width: 80%;
        
            * {
                position: relative;
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
            }

            p {
                opacity: 0;
                color: rgba(255, 0, 0, 0.534);
                text-align: right;
                width: 98%;
                font-size: 14px;
                margin-top: 4px;
                &.show{
                    opacity: 1;
                }
            }
        
            .title {
                text-align: center;
                font-size: 42px;
                margin-bottom: 15px;
            }
        
            .email {
                margin-top: 0;
                width: calc(100% - 15px);
                height: 55px;
                border: none;
                outline: none;
                background-color: #EEEEEE;
                color: #6B6B6B;
                font-size: 18px;
                padding-left: 15px;
                &.alert{
                    width: calc(100% - 17px);
                    height: 53px;
                    border: 1px solid rgba(255, 0, 0, 0.534);
                    color: rgba(255, 0, 0, 0.534);
                    background-color: rgba(255, 0, 0, 0.219);
                }
                &.pass{
                    width: calc(100% - 17px);
                    height: 53px;
                }
            }
        
            .password {
                margin-top: 0;
                width: calc(100% - 15px);
                height: 55px;
                border: none;
                outline: none;
                background-color: #EEEEEE;
                color: #6B6B6B;
                font-size: 18px;
                padding-left: 15px;
                &.alert{
                    width: calc(100% - 17px);
                    height: 53px;
                    border: 1px solid rgba(255, 0, 0, 0.534);
                    color: rgba(255, 0, 0, 0.534);
                    background-color: rgba(255, 0, 0, 0.219);
                }
                &.pass{
                    width: calc(100% - 17px);
                    height: 53px;
                }
            }
        
            .login-btn {
                height: 44px;
                border: none;
                outline: none;
                transition: all 200ms ease-in-out;
                cursor: pointer;
        
                &:hover {
                    box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
                }
        
                background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                color: white;
                font-size: 24px;
            }
        
            .remember {
                // display  : flex;
                position: relative;
                // align-items: center;
                margin-top: 5px;
                margin-bottom: 30px;
                width: 100%;
                height: 24px;
                // background-color: teal;
        
                div {
                    position: relative;
                    float: left;
                    margin-top: 0;
                    width: 20px;
                    height: 24px;
                    // background-color: red;
                    margin-right: 0.5em;
                }
        
                .remember-me {
                    border: 2px solid rgba(0, 137, 181, 1);
        
                    color: transparent !important;
                    margin-top: 2px;
                    height: 20px;
                    width: 20px;
        
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    outline: none;
                    content: none;
                }
        
                .remember-me:checked {
                    border: 2px solid rgba(0, 137, 181, 1);
                    background-color: rgb(34, 144, 180);
                    color: transparent !important;
                    margin-top: 2px;
                    height: 20px;
                    width: 20px;
        
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    outline: none;
                    content: none;
                }
        
                .forget-btn {
                    width: 90px;
                    float: right;
                    margin-top: 0;
        
                    .text {
                        color: rgba(0, 137, 181, 1);
                        text-decoration: none;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        
            .register {
                opacity: 1;
                font-size: 16px;
                margin-top: 0;
                text-align: center;
                color: #454545;
        
                a {
                    color: #454545;
                    font-weight: bold;
                    text-decoration: none;
        
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) and (max-height: 479px) {

        .container {
            position: absolute;
            background-color: rgb(255, 255, 255);
            width: 100%;
            height: 125vh;
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
        }
        
        .wrapper {
            position: absolute;
            left: 50%;
            top: 45%;
            transform: translate(-50%, -50%);
            // background-color: green;
            width: 80%;
        
            * {
                position: relative;
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
            }

            p {
                opacity: 0;
                color: rgba(255, 0, 0, 0.534);
                text-align: right;
                width: 98%;
                font-size: 14px;
                margin-top: 4px;
                &.show{
                    opacity: 1;
                }
            }
        
            .title {
                text-align: center;
                font-size: 42px;
                margin-bottom: 15px;
            }
        
            .email {
                margin-top: 0;
                width: calc(100% - 15px);
                height: 55px;
                border: none;
                outline: none;
                background-color: #EEEEEE;
                color: #6B6B6B;
                font-size: 18px;
                padding-left: 15px;
                &.alert{
                    width: calc(100% - 17px);
                    height: 53px;
                    border: 1px solid rgba(255, 0, 0, 0.534);
                    color: rgba(255, 0, 0, 0.534);
                    background-color: rgba(255, 0, 0, 0.219);
                }
                &.pass{
                    width: calc(100% - 17px);
                    height: 53px;
                }
            }
        
            .password {
                margin-top: 0;
                width: calc(100% - 15px);
                height: 55px;
                border: none;
                outline: none;
                background-color: #EEEEEE;
                color: #6B6B6B;
                font-size: 18px;
                padding-left: 15px;
                &.alert{
                    width: calc(100% - 17px);
                    height: 53px;
                    border: 1px solid rgba(255, 0, 0, 0.534);
                    color: rgba(255, 0, 0, 0.534);
                    background-color: rgba(255, 0, 0, 0.219);
                }
                &.pass{
                    width: calc(100% - 17px);
                    height: 53px;
                }
            }
        
            .login-btn {
                height: 44px;
                border: none;
                outline: none;
                transition: all 200ms ease-in-out;
                cursor: pointer;
        
                &:hover {
                    box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
                }
        
                background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
                color: white;
                font-size: 24px;
            }
        
            .remember {
                // display  : flex;
                position: relative;
                // align-items: center;
                margin-top: 5px;
                margin-bottom: 30px;
                width: 100%;
                height: 24px;
                // background-color: teal;
        
                div {
                    position: relative;
                    float: left;
                    margin-top: 0;
                    width: 20px;
                    height: 24px;
                    // background-color: red;
                    margin-right: 0.5em;
                }
        
                .remember-me {
                    border: 2px solid rgba(0, 137, 181, 1);
        
                    color: transparent !important;
                    margin-top: 2px;
                    height: 20px;
                    width: 20px;
        
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    outline: none;
                    content: none;
                }
        
                .remember-me:checked {
                    border: 2px solid rgba(0, 137, 181, 1);
                    background-color: rgb(34, 144, 180);
                    color: transparent !important;
                    margin-top: 2px;
                    height: 20px;
                    width: 20px;
        
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -o-appearance: none;
                    outline: none;
                    content: none;
                }
        
                .forget-btn {
                    width: 90px;
                    float: right;
                    margin-top: 0;
        
                    .text {
                        color: rgba(0, 137, 181, 1);
                        text-decoration: none;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        
            .register {
                opacity: 1;
                font-size: 16px;
                margin-top: 0;
                text-align: center;
                color: #454545;
        
                a {
                    color: #454545;
                    font-weight: bold;
                    text-decoration: none;
        
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}