.InvalidReset{
    display: flex;
    align-items: center;
    justify-content: center;

    .bgWrapper {
        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        background-image: url("../../../assets/background/bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        overflow: hidden;
    }

    @media only screen and (min-width: 1366px) {
        .container {
            position: absolute;
            background-color: rgba(255, 255, 255, 0.6);
            backdrop-filter: blur(3px) saturate(100%);
            border-radius: 5px;
            width: 500px;
            min-width: 528px;
            height: 350px;
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
        }
        
        .wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
        
            * {
                position: relative;
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
            }

            p {
                opacity: 0;
                color: rgba(255, 0, 0, 0.534);
                text-align: right;
                width: 98%;
                font-size: 14px;
                margin-top: 4px;
                &.show{
                    opacity: 1;
                }
            }
        
            .title {
                text-align: center;
                font-size: 32px;
                margin-bottom: 0;
                margin-top: 0;
            }
            
            .sub-title{
                text-align: center;
                margin-bottom: 5px;
                margin-top: 0;
            }
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1366px) {
        .container {
            position: absolute;
            background-color: rgba(255, 255, 255, 0.6);
            backdrop-filter: blur(3px) saturate(100%);
            border-radius: 5px;
            width: 500px;
            min-width: 528px;
            height: 350px;
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
        }

        .wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80%;

            * {
                position: relative;
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
            }

            p {
                opacity: 0;
                color: rgba(255, 0, 0, 0.534);
                text-align: right;
                width: 98%;
                font-size: 14px;
                margin-top: 4px;
                &.show{
                    opacity: 1;
                }
            }
        
            .title {
                text-align: center;
                font-size: 32px;
                margin-bottom: 0;
                margin-top: 0;
            }
            
            .sub-title{
                text-align: center;
                margin-bottom: 5px;
                margin-top: 0;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .container {
            position: absolute;
            background-color: white;
            width: 100%;
            height: calc(var(--vh, 1vh) * 100);
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
        }
        
        .wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
        
            * {
                position: relative;
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
            }

            p {
                opacity: 0;
                color: rgba(255, 0, 0, 0.534);
                text-align: right;
                width: 98%;
                font-size: 12px;
                margin-top: 4px;
                &.show{
                    opacity: 1;
                }
            }
        
            .title {
                text-align: center;
                font-size: 32px;
                margin-bottom: 0;
                margin-top: 0;
            }
            
            .sub-title{
                text-align: center;
                margin-bottom: 5px;
                margin-top: 0;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .container {
            position: absolute;
            background-color: white;
            width: 100%;
            height: calc(var(--vh, 1vh) * 100);
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
        }
        
        .wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
        
            * {
                position: relative;
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
            }

            p {
                opacity: 0;
                color: rgba(255, 0, 0, 0.534);
                text-align: right;
                width: 98%;
                font-size: 13px;
                margin-top: 4px;
                &.show{
                    opacity: 1;
                }
            }
        
            .title {
                text-align: center;
                font-size: 32px;
                margin-bottom: 0;
                margin-top: 0;
            }
            
            .sub-title{
                text-align: center;
                margin-bottom: 5px;
                margin-top: 0;
            }
        }
    }

    @media only screen and (max-width: 359px) {
        .container {
            position: absolute;
            background-color: white;
            width: 100%;
            height: calc(var(--vh, 1vh) * 100);
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
        }
        
        .wrapper {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
        
            * {
                position: relative;
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
            }

            p {
                opacity: 0;
                color: rgba(255, 0, 0, 0.534);
                text-align: right;
                width: 98%;
                font-size: 11px;
                margin-top: 4px;
                &.show{
                    opacity: 1;
                }
            }
        
            .title {
                text-align: center;
                font-size: 32px;
                margin-bottom: 0;
                margin-top: 0;
            }
            
            .sub-title{
                text-align: center;
                margin-bottom: 5px;
                margin-top: 0;
            }
        }
    }
}