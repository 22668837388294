.Password {
  display: flex;
  align-items: center;
  justify-content: center;

  .bgWrapper {
    background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
    background-image: url("../../../../assets/background/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
  }

  .dialog {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .dialog-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 95vw;
      width: 600px;
      height: 200px;
      background-color: white;
      border-radius: 5px;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      h4 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 75px;
        color: #4B4B4B;
      }

      .close {
        color: #aaaaaa;
        float: right;
        font-size: 30px;
        height: 30px;
        width: 30px;
        font-weight: bold;
        margin-right: 10px;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        &:hover {
          color: #838383;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }


  //PC HD+
  @media only screen and (min-width: 1280px) {

    .container {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(3px) saturate(100%);
      border-radius: 10px;
      width: 500px;
      min-width: 528px;
      height: 500px;
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
    }

    .wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      * {
        position: relative;
        margin-top: 15px;
        width: 100%;
        border-radius: 5px;
      }

      p {
        opacity: 0;
        color: rgba(255, 0, 0, 0.534);
        text-align: right;
        width: 98%;
        font-size: 14px;
        margin-top: 4px;

        &.show {
          opacity: 1;
        }
      }

      .title {
        text-align: center;
        font-size: 32px;
        margin-bottom: 0;
        margin-top: 0;
      }

      .sub-title {
        text-align: center;
        margin-bottom: 5px;
        margin-top: 0;
      }

      .old-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          color: #6B6B6B;
        }
      }

      .password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgb(213, 255, 204);
        }
      }

      .confirm-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgb(213, 255, 204);
        }
      }

      .reset-btn {
        margin-top: 30px;
        height: 44px;
        border: none;
        outline: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }

        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 24px;
      }
    }
  }

  //PC qHD, iPad (P,L)
  @media only screen and (min-width: 768px) and (max-width: 1366px) {

    .container {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(3px) saturate(100%);
      border-radius: 10px;
      width: 500px;
      min-width: 528px;
      height: 500px;
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
    }

    .wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      * {
        position: relative;
        margin-top: 15px;
        width: 100%;
        border-radius: 5px;
      }

      p {
        opacity: 0;
        color: rgba(255, 0, 0, 0.534);
        text-align: right;
        width: 98%;
        font-size: 14px;
        margin-top: 4px;

        &.show {
          opacity: 1;
        }
      }

      .title {
        text-align: center;
        font-size: 32px;
        margin-bottom: 0;
        margin-top: 0;
      }

      .sub-title {
        text-align: center;
        margin-bottom: 5px;
        margin-top: 0;
      }

      .old-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          color: #6B6B6B;
        }
      }

      .password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgb(213, 255, 204);
        }
      }

      .confirm-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgb(213, 255, 204);
        }
      }

      .reset-btn {
        margin-top: 30px;
        height: 44px;
        border: none;
        outline: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }

        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 24px;
      }
    }
  }

  //Phone Portrait
  @media only screen and (max-width: 767px) {

    .container {
      position: absolute;
      background-color: rgb(255, 255, 255);
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
    }

    .wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      * {
        position: relative;
        margin-top: 15px;
        width: 100%;
        border-radius: 5px;
      }

      p {
        opacity: 0;
        color: rgba(255, 0, 0, 0.534);
        text-align: right;
        width: 98%;
        font-size: 12px;
        margin-top: 4px;

        &.show {
          opacity: 1;
        }
      }

      .title {
        text-align: center;
        font-size: 32px;
        margin-bottom: 0;
        margin-top: 0;
      }

      .sub-title {
        text-align: center;
        margin-bottom: 5px;
        margin-top: 0;
      }

      .old-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          color: #6B6B6B;
        }
      }

      .password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgb(213, 255, 204);
        }
      }

      .confirm-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgb(213, 255, 204);
        }
      }

      .reset-btn {
        margin-top: 30px;
        height: 44px;
        border: none;
        outline: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }

        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 24px;
      }
    }
  }

  // iPhone 4
  @media only screen and (max-width: 359px) {

    .container {
      position: absolute;
      background-color: white;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.233);
    }

    .wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      * {
        position: relative;
        margin-top: 15px;
        width: 100%;
        border-radius: 5px;
      }

      p {
        opacity: 0;
        color: rgba(255, 0, 0, 0.534);
        text-align: right;
        width: 98%;
        font-size: 11px;
        margin-top: 4px;

        &.show {
          opacity: 1;
        }
      }

      .title {
        text-align: center;
        font-size: 32px;
        margin-bottom: 0;
        margin-top: 0;
      }

      .sub-title {
        text-align: center;
        margin-bottom: 5px;
        margin-top: 0;
      }

      .old-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          color: #6B6B6B;
        }
      }

      .password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgb(213, 255, 204);
        }
      }

      .confirm-password {
        box-sizing: border-box;
        margin-top: 0;
        height: 51px;
        border: none;
        outline: none;
        background-color: #EEEEEE;
        color: #6B6B6B;
        font-size: 18px;
        padding-left: 15px;

        &.alert {
          border: 1px solid rgba(255, 0, 0, 0.534);
          color: rgba(255, 0, 0, 0.534);
          background-color: rgb(255, 203, 203);
        }

        &.pass {
          border: 1px solid rgba(43, 255, 0, 0.534);
          color: rgba(10, 46, 3, 0.836);
          background-color: rgb(213, 255, 204);
        }
      }

      .reset-btn {
        margin-top: 30px;
        height: 44px;
        border: none;
        outline: none;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 0 16px 3px rgba(69, 180, 195, 1)
        }

        background: linear-gradient(90deg, rgba(69, 180, 195, 1) 0%, rgba(0, 137, 181, 1) 100%);
        color: white;
        font-size: 24px;
      }
    }
  }
}